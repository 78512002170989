.camera-add-info {
  margin-bottom: 18px;

  .camera-add-info-photo {
    display: flex;
    justify-content: center;
    font-size: 0;
    width: 100%;
    margin-bottom: 32px;

    img {
      height: 100px;
    }
  }

  .camera-add-info-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #E2E8F0;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    .camera-add-info-name {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #0F172A
    }

    .camera-add-info-value {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #0F172A;
    }
  }
}

.subscription-select-title {
  margin-bottom: 18px;
  margin-top: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0F172A;
}

.subscriptions-select-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin-bottom: 20px;

  .subscriptions-select-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 9px 16px;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    width: 263px;
    transition: all 300ms ease-in-out;

    &:hover {
      border: 1px solid #E42F31;
    }

    &.active {
      border: 1px solid #E42F31;

      .subscription-select-cameras,
      .subscription-select-period,
      .subscription-select-name {
        color: #E42F31;
      }
    }

    .subscription-select-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #0F172A;
      transition: all 150ms ease-in-out;
    }

    .subscription-select-period {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #0F172A;
      transition: all 150ms ease-in-out;
    }

    .subscription-select-cameras {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #94A3B8;
      transition: all 150ms ease-in-out;
    }
  }
}

.subscription-plans-group {
  display: block;
  width: 100%;
  padding: 9px 16px;
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;

  .subscription-plans-group-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
}

.new-subscriptions-select-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 15px;
  margin-bottom: 20px;

  &.subscriptions-grid-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }

  .subscriptions-select-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 9px 16px;
    gap: 10px;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    width: 263px;
    transition: all 300ms ease-in-out;
    min-height: 85px;

    &.isDeactivated {
      cursor: not-allowed;
      color: #94A3B8;
      border: 1px solid #94A3B8;
      &:hover {
        border: 1px solid #94A3B8;
      }
      .subscriptions-select-item-recommended {
        background-color: #94A3B8;
      }
    }

    &:hover {
      border: 1px solid #E42F31;
    }

    &.active {
      border: 1px solid #E42F31;

      .subscription-select-period,
      .subscription-select-name {
        color: #E42F31;
      }

      .subscriptions-select-item-recommended {
        background-color: #E42F31;
      }
    }

    .subscriptions-select-item-recommended {
      font-weight: 400;
      font-size: 10px;
      border-radius: 4px;
      color: #FFFFFF;
      padding: 2px 6px;
      background-color: #94A3B8;
    }

    .subscription-select-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      transition: all 150ms ease-in-out;
    }

    .subscription-select-period {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      transition: all 150ms ease-in-out;
    }
  }
}

.add-camera-settings-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 40px;
  min-height: 40px;
  transition: height 0.2s ease;

  &.contentShown {
    .add-camera-settings-header {
      opacity: 1;
    }
    .add-camera-settings-content {
      display: block;
      margin-top: 12px;
      margin-bottom: 24px;
    }
  }

  .add-camera-settings-content {
    display: none;
  }
}

.add-camera-settings-content {
  .settings-line:last-child {
    border-bottom: 1px solid transparent;
  }
}
.add-camera-settings-header {
  display: flex;
  cursor: pointer;
  align-items: center;
  opacity: 0.6;
  padding: 8px 0;
  font-weight: 500;
  justify-content: space-between;
  font-size: 18px;
  border-bottom: 1px solid #E2E8F0;

  .add-camera-settings-header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.contentShown {
    opacity: 1;
  }

  &.sectionChecked {
    opacity: 1;
    color: #31a845;
  }
}

.wizard-tabs-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 50px;

  .wizard-step-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    &.activeStep {
      .wizard-step-label {
        color: #E42F31;
      }
      .wizard-step-dot {
        border: 1px solid #E42F31;
      }

      &:before {
        background: #E42F31;
      }
    }

    &.savedStep {
      .wizard-step-label {
        color: #E42F31;
      }
      .wizard-step-dot {
        border: 1px solid #E42F31;
      }
      &:before, &:after {
        background: #E42F31;
      }
    }

    &:after {
      width: calc(50% - 15px);
      height: 1px;
      content: "";
      background: #0F172A;
      position: absolute;
      display: inline-block;
      top: 0;
      left: 50%;
      transform: translate3d(15px, 15px, 0);
    }

    &:before {
      width: calc(50% - 15px);
      height: 1px;
      content: "";
      background: #0F172A;
      position: absolute;
      display: inline-block;
      top: 0;
      right: 50%;
      transform: translate3d(-15px, 15px, 0);
    }

    &:first-child:before {
      display: none;
    }

    &:last-child:after {
      display: none;
    }

    .wizard-step-dot {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #0F172A;
      width: 30px;
      color: #0F172A;
      font-size: 14px;
      font-weight: 600;
      height: 30px;
      border-radius: 50%;
    }

    .wizard-step-label {
      color: #0F172A;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      white-space: nowrap;
      margin-top: 8px;
    }
  }
}
