.custom-select {
    position: relative;

    .select-trigger {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 6px;
        background-color: #fff;
        color: #000;
        height: 22px;

        .placeholder {
            color: #999;
            text-indent: 5px;
        }

        .option-arrow {
            background-image: url("../../images/iconSVG/images/down_arrow.svg");
            width: 16px;
            height: 16px;
            position: absolute;
            right: 12px;
        }

        .selected-value {
            display: flex;
            align-items: center;
            gap: 4px;
            border-radius: 3px;
            padding: 1px 8px;
            height: 21px;
            width: auto;
            overflow: hidden;
            white-space: nowrap;
        }

        .clear-icon {
            background-image: url("../../images/iconSVG/images/close.svg");
            width: 16px;
            height: 16px;
            margin-top: 1px;
            cursor: pointer;
        }
    }

    .select-options {
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
        margin-top: 1px;
        padding: 0;
        list-style: none;
        background-color: #fff;
        border: 1px solid #f1f5f9;
        border-top: none;
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);

        .option-container {
            padding: 5px;
            .option {
                padding: 6px;
                height: 22px;
                cursor: pointer;

                &.selected {
                    border-radius: 6px;
                    background-color: #f1f5f9;
                    position: relative;
                }

                &.selected::after {
                    content: "";
                    background-image: url("../../images/iconSVG/images/check.svg");
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    transform: translateY(-50%);
                }
            }
        }
    }

    &.open {
        .select-trigger {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
}
