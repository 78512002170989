@import "src/components/styles/index";


.date-picker-wrapper {
    position: relative;

    input {
        padding-right: 24px!important;
    }

    .img-container {
        top: 50%!important;
        display: flex;
        right: 8px!important;
        transform: translateY(-50%);
        left: auto!important;
        bottom: auto!important;
    }
}

.camera-view {
    display: flex;

    .camera-view-short {
        margin-right: 21px;
        width: 268px;
        min-width: 268px;

        .camera-item {
            margin: 0 0 16px 0;
        }
    }

    .camera-view-content {
        display: flex;
        flex: 1;
        flex-direction: column;

        .nav {
            align-self: flex-start;
            margin-bottom: 16px;
        }

        .content-section {
            //flex: 1;
            width: auto;
        }

        .camera-location-form {
            padding: 24px 24px 0 24px;

            .form-group {
                margin-bottom: 16px;

                /*
                Hide number arrows
                Chrome, Safari, Edge, Opera
                */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /*
                Hide number arrows
                Firefox
                */
                input[type=number] {
                    -moz-appearance: textfield;
                }

                .error {
                    font-size: 14px;
                    margin: 10px 0px 0px 5px;
                    color: red;
                }

                select:has(+ .error), input:has(+ .error) {
                    border: 1px solid #e42f31;
                }
            }

            .camera-location-buttons {
                display: flex;
                width: 100%;
                justify-content: flex-end;

                .btn + .btn {
                    margin-left: 12px;
                }
            }
        }

        .form-group {
            margin-bottom: 24px;
        }
    }
}

.camera-model-info {
    display: flex;
    margin-bottom: 32px;
    justify-content: space-between;
}

.linked-cameras-footer {
    margin-top: 32px;
}

.camera-model-info-item {
    .label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #0f172a;
        margin-bottom: 12px;
    }

    .value {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #0f172a;
    }
}

.camera-settings-title {
    color: $color_900;
    font-size: 28px;
    font-weight: 900;
}

.camera-view-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .btn + .btn {
        margin-left: 12px;
    }
}

.camera-settings {
    margin-bottom: 24px;
}

//notifications

.camera-settings-notification-swipers {
    display: flex;
    margin-bottom: 24px;
}

.camera-settings-notification-swipers-block {
    width: 200px;

    .setting-title {
        display: inline-block;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: $color_900;
        margin-bottom: 12px;
    }
}

.camera-settings-notification-phone + .camera-settings-notification-phone {
    margin-top: 24px;
}

.camera-settings-notification-phone {
    span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color_900;
    }
}

.corruptedLocationPlaceholder {
    margin: 12px 24px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
        margin: 0;
        font-weight: 500;
    }
}

.camera-marker-tooltip {
    position: absolute;
    background-color: white;
    border-radius: 5px;
    font-size: 12px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);

    .camera-marker-tooltip-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &::before {
        content: " ";
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: 10px;
        margin-left: calc(-10px);
        top: 100%;
        border-top-color: white;
    }

    .camera-photo {
        display: inline-block;

        img {
            height: 80px;
        }
    }

    .camera-info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 110px;

        .camera-name {
            display: block;
            text-overflow: ellipsis;
            word-wrap: break-word;
            overflow: hidden;
            max-height: 3.6em;
            line-height: 1.2em;
        }
    }

    .tooltip-save {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tooltip-close {
        height: 100%;

        .icon {
            width: 12px;
            height: 12px;
        }
    }
}


.contact-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
        border: 1px solid $color_300;
        border-radius: 6px;
        padding: 8px 12px;
        width: 740px;
        height: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    button {
        border: none;
        background-color: white;
        background-image: url("../../images/iconSVG/elements/grey_trash.svg");
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
    }
}

//usage
.camera-settings-usage-section {
    display: flex;

    &.with-paddings {
        padding: 0 43px;

        &.for-photos {
            padding: 0 43px 18px;
        }
    }
}

.long-cell-with-copy {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: copy;
}

.modal-buttons-block.logs-modal-footer {
    justify-content: flex-end;
}

.logs-actions-btns {
    display: flex;
    align-items: center;

    button {
        width: 100%;
        margin-right: 4px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.content-header.with-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logs-details-json-wrapper {
    max-height: 70vh;
    overflow-y: auto;
}

.cell-with-copy {
    cursor: copy;
}

.load-more-logs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}

.camera-settings-usage-block {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.camera-settings-usage-input {
    display: flex;
    margin-bottom: 24px;

    &.alone {
        margin-bottom: 0;
    }

    &.head {
        .camera-settings-usage-title, .camera-settings-usage-txt {
            font-weight: 600;
            font-size: 18px;
        }
    }
}

.summary-header {
    display: flex;
    flex-direction: column;

    .usage-dropdown {
        width: 120px;
    }
}

.camera-settings-usage-title {
    width: 55%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $color_900;
}

// map

.empty-map {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 28px;

    .empty-map-image {
        margin-bottom: 36px;
    }

    .empty-map-title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #0f172a;
        margin-top: 0;
        margin-bottom: 16px;
    }

    .empty-map-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #0f172a;
        margin-bottom: 36px;
    }
}

// icons

.empty-map-image {
    background-image: url("../../images/iconSVG/images/map.svg");
    background-repeat: no-repeat;
    width: 345px;
    height: 345px;
}

.camera-usage-section {
    margin-bottom: 16px;
}

.summary-divider {
    margin-bottom: 18px;
    margin-top: 0;
    border: 2px solid #e2e8f0;
}

.camera-usage-chart {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    background: #ffffff;
    margin-bottom: 16px;

    .usage-chart-header {
        padding-left: 43px;
        padding-top: 28px;

        .usage-dropdown {
            width: 120px;
        }
    }

    .usage-chart-title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 9px;
    }

    .usage-period {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }

    .usage-photos-count {
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.0375em;
        margin-bottom: 6px;
        margin-top: -3px;
    }

    .usage-chart-divider {
        margin-top: 27px;
        margin-bottom: 18px;
        border: 2px solid #e2e8f0;
    }

    .usage-chart-content {
        padding-left: 30px;
        padding-right: 35px;
        padding-bottom: 33px;
    }

    .chart-tbd {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 250px;
        border-radius: 6px;

        .not-found-text {
            font-family: Inter;
            font-size: 24px;
            font-weight: 700;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: left;
            color: #0f172a;
        }

        span {
            color: #ffffff;
            font-size: 18px;
        }
    }
}

.camera-settings-notification-info {
    .camera-notification-phones {
        margin-bottom: 24px;

        .form-group {
            margin-bottom: 12px;
        }

        .input-group {
            margin-bottom: 12px;
        }
    }

    .camera-notification-emails {
        .form-group {
            margin-bottom: 12px;
        }

        .input-group {
            margin-bottom: 12px;
        }
    }
}

.input-group {
    display: flex;
    align-items: center;

    .icon-btn {
        margin-left: 11px;
        cursor: pointer;
    }
}

.camera-specs-description {
    p {
        margin: 0;
    }
}

.camera-specs-features {
    ul {
        margin: 0;
    }
}

.camera-map {
    padding: 12px;
}

.sms-type-commands-note {
    margin-bottom: 24px;
    padding: 10px 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 6px;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    span {
        font-weight: 600;
    }
}

.commands-list-table, .linked-cameras-list-table {
    thead {
        background: #f3f5f7;

        th {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 24px;
        }
    }
    tbody {
        tr {
            td {
                background: #ffffff;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 24px;
            }
        }
    }

    td.input-cell {
        text-align: center;
    }

    td.empty {
        color: $color_400;
    }

    .linked-status-cell {
        &.Active {
            color: $color_green;
        }
        &.Inactive {
            color: $color_red;
        }
    }

    .status-cell {
        height: 17px;
        border-radius: 20px;
        padding: 2px 8px;
        font-weight: 500;
        font-size: 11px;
        text-align: center;
        color: #ffffff;
        box-sizing: border-box;
        background-color: #cbd5e1;

        &.success, &.active {
            background-color: #31a845;
        }

        &.expired,
        &.deactivated,
        &.failed {
            background-color: #e42f31;
        }

        &.pending {
            background-color: #333f51;
        }
    }

    .photo-view-link {
        cursor: pointer;
    }
}

.subscription-status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;

    .subscription-status-text {
    }
}

.icon {
    &.icon-trash {
        width: 18px;
        cursor: pointer;
        height: 18px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../images/iconSVG/elements/grey_trash.svg");
    }

    &.icon-clock {
        width: 17px;
        height: 17px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../images/iconSVG/camera/icon_clock.svg");
    }
}

.summary-loader {
    padding: 28px 0;
}

.summary-recognition {
    display: flex;
    align-items: center;
    text-transform: capitalize;

    .recognition-icon {
        margin-right: 4px;
    }
}

.content-section {
    margin-bottom: 16px;

    .billing-blocks {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }
    .billing-block {
        display: flex;
        padding: 15px 0 15px 0;
        border-bottom: solid 1px #eeeeee;

        &:last-child {
            border-bottom: none;
        }
    }
    .billing-name {
        display: flex;
        font-weight: 500;
        width: 30%;
    }

    .billing-value {
        display: flex;
        justify-content: flex-start;
    }
}
