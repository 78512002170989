$snow: #ffffff;
$color_50: #f8fafc;
$color_100: #F3F5F7;
$color_200: #e2e8f0;
$color_300: #cbd5e1;
$color_400: #94a3b8;
$color_800: #333F51;
$color_900: #0f172a;
$color_red: #E42F31;
$color_green: #64BF23;
$color_orange: #FF8C22;
