
.device-list {
  width: 100%;

  .device-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid #E2E8F0;
    padding-bottom: 24px;
    margin-bottom: 24px;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .device-item-description {
      display: flex;
      gap: 5px;

      span {
        display: inline-block;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #0F172A;
      }
    }

    .device-controls {
      display: flex;
      align-items: center;
      align-content: center;
      gap: 25px;

      .device-last-seen {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #0F172A;
      }

      .icon {
        &.icon-arrow-right {
          background-image: url("../../../../images/iconSVG/icon-right.svg");
          background-repeat: no-repeat;
          background-position: center;
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
