@import "src/components/styles/index";

.render-tags-wrapper {
  display: flex;
  align-items: center;


  h4 {
    font-weight: 600;
    font-size: 16px;
    margin: 0 8px 4px 0;
    color: $color_900;
  }

  .render-tag {
    padding: 3px 6px;
    text-decoration: none;
    border-radius: 6px;
    line-height: 14px;
    font-size: 14px;
    background: $color_900;
    color: #fff;
    border: 1px solid $color_900;
    margin-right: 4px;
    margin-bottom: 4px;
    transition: background 0.2s ease, border 0.2s ease;

    &:hover {
      background: $color_red;
      border: 1px solid $color_red;
    }
  }
}
