input[type="number"].input,
input[type="time"].input,
input[type="text"].input {
  padding: 8px 12px 8px 12px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #CBD5E1;
  border-radius: 6px;
  box-sizing: border-box;
  outline: none;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #0F172A;

  transition: border 150ms ease-in-out;

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #94A3B8;
  }

  &:hover {
    border: 1px solid #94A3B8;
  }

  &:active {
    border: 1px solid #94A3B8;
  }

  &:focus {
    border: 1px solid #94A3B8;
  }

  &:not(:placeholder-shown) {
    border: 1px solid #CBD5E1;
  }

  &:disabled {
    background: #F3F5F7;
    border: 1px solid #CBD5E1;
  }

  &.not-valid {
    border: 1px solid #E42F31;
  }
}

textarea {
  padding: 8px 12px;
  gap: 10px;
  background: #FFFFFF;
  border: 1px solid #CBD5E1;
  border-radius: 6px;
  outline: none;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #0F172A;

  transition: border 150ms ease-in-out;

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #94A3B8;
  }

  &:hover {
    border: 1px solid #94A3B8;
  }

  &:active {
    border: 1px solid #94A3B8;
  }

  &:focus {
    border: 1px solid #94A3B8;
  }

  &:not(:placeholder-shown) {
    border: 1px solid #CBD5E1;
  }

  &:disabled {
    background: #F3F5F7;
    border: 1px solid #CBD5E1;
  }

  &.not-valid {
    border: 1px solid #E42F31;
  }
}

input[type="checkbox"] {
  box-sizing: border-box;
  appearance: none;
  border: 1px solid #94A3B8;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  background: #FFFFFF;
  cursor: pointer;

  &:hover {
    border: 1px solid #333F51;
  }
}

input[type="checkbox"]:checked {
  background-image: url("../images/iconSVG/elements/check.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  background-position: center;
  border: 1px solid #333F51;
}

.custom-checkbox-old {
  position: relative;
  cursor: pointer;

  input[type="checkbox"] {
    display: inline-block;
    border: 1px solid #94A3B8;
    border-radius: 2px;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    cursor: pointer;

    &:hover {
      border: 1px solid #333F51;
    }
  }

  input[type="checkbox"]:checked+label::before {
    content: "";
    display: block;
    position: absolute;
    text-align: center;
    height: 20px;
    width: 20px;
    left: 0;
    top: 0;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #333F51;
    cursor: pointer;
    box-sizing: border-box;
  }

  input[type="checkbox"]:checked+label::after {
    content: url("../images/iconSVG/elements/check.svg");
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    margin-top: -8px;
    top: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    box-sizing: border-box;
  }
}

select {
  &.select {
    display: inline-block;
    padding: 8px 12px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    border-radius: 6px;
    outline: none;
    height: 40px;

    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #0F172A;
    }

    &:active,
    &:hover {
      border: 1px solid #94A3B8;
    }

    :disabled {
      background: #F3F5F7;
      border: 1px solid #CBD5E1;
      border-radius: 6px;
    }
  }
}

.radio-input {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 24px;

  .label-text {
    font-size: 14px;
    line-height: 16px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      border: 1px solid #333F51;
    }

    &:checked ~ .checkmark:after {
      opacity: 1;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    border: 1px solid #CBD5E1;
    box-sizing: border-box;
    border-radius: 50%;
    transition: all ease-in-out 100ms;

    &:after {
      content: "";
      position: absolute;
      display: block;
      opacity: 0;

      top: 4px;
      left: 4px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #333F51;
      transition: all ease-in-out 100ms;
    }
  }

  &:hover input ~ .checkmark {
    border: 1px solid #333F51;
  }
}