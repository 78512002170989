@import "src/components/styles/index";

.settings-line {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E2E8F0;
  padding-top: 24px;
  padding-bottom: 23px;

  &.indent-1 {
    padding-left: 36px;
  }

  &.line-hidden {
    display: none;
  }

  &:first-child {
    margin-top: 0;
    padding-top: 0;
  }
}

.settings-line-name {
  display: flex;
  align-items: center;
  width: 37%;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0F172A;
    margin: 0;
  }
}

.settings-line-value {
  display: flex;
  justify-content: start;
  align-items: center;

  .radio-input {
    margin-right: 18px;
  }
}

.settings-line {
  .select {
    padding: 8px 12px;
    width: 350px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    border-radius: 6px;
  }

  input.input {
    height: 36px;
    width: 350px;
  }
}
