@import "src/components/styles/index";

.profile-info-footer {
    display: flex;
}

.profile-info-swipers {
    display: flex;
}

.profile-info-swipers-block {
    margin-right: 40px;
}

.profile-onx-header {
    display: flex;
    margin-bottom: 24px;
}

.profile-onx-swipers {
    width: 100%;
}

.profile-onx-auth-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .profile-onx-auth-toggle {
        display: flex;
        gap: 20px;
    }
}

.profile-info-buttons {
    display: flex;
    width: 100%;
}

.page-wrapper {
    display: flex;
    justify-content: center;

    .page-content {
        width: $size_list_desktop;
    }
}

.content-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 24px;
}

.page-title {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 28px;
}

.page-sub-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.content-section {
    background: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 28px;
    width: $size_list_desktop;

    &.no-body {
        background: transparent;
        border-radius: 0;
        padding: 0;
    }

    &.thin-row {
        padding: 8px 10px;
    }
}


.twoInputs {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-group {
        width: 49%;
    }
}



.threeInputs {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-group {
        width: 32%;
    }
}

.form-group {
    margin-bottom: 12px;
    label {
        display: inline-block;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #0f172a;
        margin-bottom: 6px;
    }

    .input {
        display: inline-block;
        width: 100%;
    }

    .textarea {
        display: inline-block;
        width: 100%;
    }
}

.profile-info-footer {
    margin-bottom: 24px;
}

.profile-info-buttons {
    width: 100%;
    justify-content: flex-end;

    .btn + .btn {
        margin-left: 12px;
    }
}

.profile-addons-list {
    .addon-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 22px 16px;
        gap: 10px;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
    }

    .addon-description {
        .addon-description-type {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #0f172a;
            margin-bottom: 8px;
        }

        .addon-price {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #0f172a;
        }
    }

    .addon-date {
        .addon-expires,
        .addon-purchased {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #0f172a;
            text-align: right;
        }

        .addon-purchased {
            margin-bottom: 8px;
        }
    }
}

.subscription-product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    .subscription-name {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        color: #0f172a;
    }

    .subscription-lines {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        .subscription-line {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            color: #e42f31;
            cursor: pointer;
        }

        .no-lines {
            font-weight: 500;
            color: #94a3b8;
        }
    }
}

.onx-header-log {
    display: inline-block;
    background-image: url("../../images/iconSVG/onx/hunt_md_light_bg.svg");
    background-repeat: no-repeat;
    width: 138px;
    height: 36px;
    background-size: cover;
}

.onx-auth-warning {
    margin-top: 0;
}

.profile-onx-camera-block {
    display: flex;
    flex-direction: column;

    .camera-onx-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .camera-item-onx {
            flex-grow: 1;
            display: flex;
            align-items: center;
            align-content: flex-start;
            height: 100px;
            background-color: white;
            padding: 5px;
            box-sizing: border-box;
            gap: 20px;

            .camera-item-header-row {
                display: flex;
                align-content: center;
                width: 60px;

                img {
                    height: 77px;
                }
            }
        }

        .camera-item-info-onx {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            white-space: nowrap;
        }

        .camera-item-row-onx {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .camera-item-name-text {
                overflow: visible;
                width: 100%;
            }
        }
    }
}

.profile-status-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .profile-status-subtitle {
        font-size: 16px;
    }

    .profile-status-change {
        display: flex;
        font-size: 16px;
        flex-direction: row;
        align-items: center;

        .balance-wrapper {
            margin-left: 8px;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            strong {
                margin-left: 4px;
            }
        }

        .pay-now-btn {
            margin-left: 8px;
        }
    }

    .profile-status {
        padding: 2px 4px;
        margin-left: 4px;
        border-radius: 3px;
        color: #FFFFFF;
        font-weight: 500;
        text-transform: capitalize;

        &.active {
            background: #31A845;
        }
        &.not-active {
            background: #E42F31;
        }
        &.photo-limit-reached {
            background: #E42F31;
        }
        &.suspended {
            background: #0F172A;
        }
    }
}
.content-section.status-section {
    margin-bottom: 30px;
}
