@import "src/components/styles/index";

.header-settings{
    width: $size_list_desktop;
    display: flex;
    justify-content: space-between;

}

.header-settings-right-side {
    display: flex;
    flex-direction: row;

    & > button {
        margin-left: 12px;

        &:first-child {
            margin-left: 0;
        }
    }

    & > a {
        margin-left: 12px;
        text-decoration: none;

        &:first-child {
            margin-left: 0;
        }
    }

    .linked-cameras-dropdown {
        margin-left: 12px;
        position: relative;
    }
}
.linked-cameras-dropdown {
    position: relative;

    &.showLinked {
        .camera-linked-list-wrapper {
            opacity: 1;
            pointer-events: all;
            transform: translateY(-20px);
        }
    }
}

.camera-linked-list-wrapper {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease, transform 0.2s ease;
    position: absolute;
    top: 100%;
    right: 0;
    background: #fff;
    border-radius: 3px;
    z-index: 1;
    transform: translateY(-30px);
    list-style: none;
    margin: 0;
    padding: 0;
    width: 150px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);

    &:after {
        content: "";
        cursor: pointer;
        display: inline-block;
        border-bottom: 5px solid #fff;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        position: absolute;
        top: -5px;
        right: 11px;
        transform: translateX(-50%);
    }

    &:before {
        content: "";
        cursor: pointer;
        display: inline-block;
        border-bottom: 6px solid rgba(0, 0, 0, 0.1);
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        position: absolute;
        top: -6px;
        right: 9px;
        transform: translateX(-50%);
    }

    ul {
        max-height: 300px;
        overflow-y: auto;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;

        &:hover {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0px);
        }

        li {
            display: flex;
            width: 100%;
            border-bottom: 1px solid rgba(0,0,0,0.2);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            height: 100%;
            padding: 4px 8px;
            align-items: center;
            box-sizing: border-box;
            font-size: 14px;
            color: $color_900;
            text-decoration: none;

            input[type="checkbox"] {
                margin-right: 4px;
            }

            &:hover {
                color: $color_red;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.header-settings-name {
    color: $color_900;
    font-size: 28px;
    font-weight: 900;
    margin: 0 0 31px 0;
}
