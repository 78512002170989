.camera-status-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .camera-status-subtitle {
    font-size: 16px;
  }

  .camera-status-change {
    display: flex;
    flex-direction: row;
    align-items: center;

    button:not(:last-child) {
      margin-right: 12px;
    }
  }

  .camera-status {
    padding: 2px 4px;
    border-radius: 3px;
    color: #FFFFFF;
    font-weight: 500;
    text-transform: capitalize;

    &.active {
      background: #31A845;
    }
    &.not-active {
      background: #E42F31;
    }
    &.photo-limit-reached {
      background: #E42F31;
    }
    &.suspend {
      background: #0F172A;
    }
  }
}
