.form-row {
    margin-bottom: 18px;

    &.form-row-horizontal {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 18px;

        .form-group {
            margin-bottom: 0;

            .form-group-calendar {
                position: absolute;
                top: 20px;
                z-index: 1;
            }

            .img-container {
                cursor: pointer;
                position: absolute;
                left: 155px;
                bottom: 110px;
            }

            .img-container-second {
                cursor: pointer;
                position: absolute;
                left: 320px;
                bottom: 110px;
            }

            .input {
                &.date {
                    position: relative;
                }
            }
        }

        // nested horizontal row
        .form-row-horizontal {
            gap: 12px;
        }
    }

    .form-group {
        width: 100%;
        .select {
            width: 100%;
        }
    }
}

.modal-video-player {
    display: block;
    width: 100%;
}
