.general-nav {
  padding: 5px 0;
  margin-bottom: 12px;

  .nav-item:first-child {
    padding-left: 0;
  }
}

.reports-datepicker {
  width: 220px;
}

.general-filters-wrapper {
  margin: 24px 0 12px;
}

.general-cameras-filter {
  min-width: 300px;
}


.date-picker-wrapper {
  position: relative;

  input {
    padding-right: 24px!important;
  }

  .img-container {
    top: 50%!important;
    display: flex;
    right: 8px!important;
    transform: translateY(-50%);
    left: auto!important;
    bottom: auto!important;
  }
}
.form-group-calendar {
  position: absolute!important;
  top: 100%!important;
  z-index: 1!important;
}

.general-filters-line {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
}

.general-filters-btns {
  display: flex;
  margin-left: 18px;
  flex: 1;
  justify-content: flex-end;

  button {
    white-space: nowrap;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.recognition-dates-filter {
  margin-bottom: 0!important;
  max-width: 400px;
  .form-group {
    position: relative;
  }
}
