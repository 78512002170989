.guest-camera-info-preview {
  gap: 10px;
  padding: 15px;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 16px;

  .camera-name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #0F172A;
    margin-bottom: 15px;
  }

  .camera-info-provider {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.05em;
    color: #94A3B8;
  }

  .camera-photos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    padding: 10px 0;
    gap: 10px;
  }

  .camera-photo {
    display: inline-block;
    img {
      height: 100px;
    }
  }

  .camera-info-section {
    margin-bottom: 10px;
  }
}