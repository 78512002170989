.empty-block {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    .empty-block-txt {
        font-size: 25px;
    }
}

.empty-title-container {
    display: flex;
    justify-content: flex-start;
    width: 80%;
    .empty-title {
        font-size: 32px;
        font-weight: 700;
        align-self: flex-start;
    }
}

.empty-search {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;

    .empty-search-image {
        margin-bottom: 36px;
    }

    .empty-search-title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #0f172a;
        line-height: 29px;
        margin-bottom: 16px;
        margin-top: 0;
    }

    .empty-search-comment {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-top: 0;
        margin-bottom: 36px;
    }
}

// icons

.empty-search-image {
    background-image: url("../../images/iconSVG/images/search.svg");
    background-repeat: no-repeat;
    width: 345px;
    height: 345px;
}

.empty-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px;
    p {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }
    span {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin: 10px;
    }
}

// icons

.empty-page-image {
    background-image: url("../../images/iconSVG/images/404.svg");
    background-repeat: no-repeat;
    width: 345px;
    height: 345px;
}
