.photos-list-container {
  .photos-for-days {
    margin-top: 24px;

    .photos-date {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #0F172A;
      margin-bottom: 24px;
    }
  }

  .photos-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .photo-item {
      position: relative;
      line-height: 0;
      width: 256px;
      background-color: #e1e1e1;
      margin: 0;
      text-align: center;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      display: flex;
      min-height: 144px;
      z-index: 0;
      transition: width 300ms ease-in-out, border 150ms ease-in-out;
      border: 0px solid #ffffff;
      box-sizing: border-box;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      .photo-item-overlay {
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(15, 23, 42, 0.5);
        z-index: 5;
        visibility: hidden;
        opacity: 0;

      }

      &:hover {
        .photo-item-overlay {
          opacity: 1;
          visibility: visible;
          transition: opacity 0.3s, visibility 0.3s;
        }
      }

      .photo-delete {
        position: absolute;
        top: 11px;
        left: 11px;
        z-index: 7;
        cursor: pointer;
      }

      .photo-like {
        position: absolute;
        right: 11px;
        bottom: 11px;
        z-index: 100;
        cursor: pointer;
      }

      .photo-view-btn {
        position: absolute;
        width: 88px;
        height: 36px;
        top: 50%;
        left: 50%;
        margin-left: -44px;
        margin-top: -18px;

        a {
          text-decoration: none;
          color: #FFFFFF;
        }
      }

      .recognition-icons {
        position: absolute;
        display: flex;
        bottom: 10px;
        left: 10px;
        z-index: 7;
        width: 100%;

        .recognition-info {
          display: flex;
          align-items: center;
          margin-right: 5px;

          .recognition-icon {
            margin-left: 3px;
          }
        }

        .recognition-icon-count {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #FFFFFF;
        }
      }

      .photo-liked {
        position: absolute;
        right: 11px;
        bottom: 11px;
      }

      .video-preview {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -15px;
        margin-left: -15px;
      }

      .photo-state {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 2px 6px;
        background: #FFFFFF;
        border-radius: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #000000;

        &.success {
          background: #31A845;
          color: #FFFFFF;
        }

        &.error {
          background: #E42F31;
          color: #FFFFFF;
        }
      }

      .delete-photo-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: rgb(0 0 0 / 37%);
        opacity: 0;
        transition: opacity 300ms ease-in-out;

        .delete-photo-item-spinner {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -32px;
          margin-left: -32px;
          z-index: 11;
        }

        &.visible {
          opacity: 1;
          z-index: 10;

          .delete-photo-item-spinner {
            animation: spin-anim 1.2s linear infinite;
          }
        }
      }

      .photo-like-loader {
        display: inline-block;
        width: 12px;
        height: 12px;
        border: 2px solid;
        border-color: #CBD5E1 #CBD5E1 #CBD5E1 transparent;
        border-radius: 50%;
        animation: spin-anim 1.2s linear infinite;
      }
    }
  }

  .photo-item-select {
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
  }

  &.two {
    .photo-item {
      width: 390px;
    }
  }

  &.select-mode {
    .photo-item-overlay {
      display: none !important;
    }

    .photo-item-select {
      display: block;
    }

    .photo-item {
      background-color: #E2E8F0;
    }

    .photo-item-selected {
      border: 10px solid #E2E8F0;

      img {
        border-radius: 8px;
      }
    }
  }
}

.delete-photo-item-spinner {
  display: inline-block;
  width: 48px;
  height: 48px;
  border: 8px solid;
  border-color: #CBD5E1 #CBD5E1 #CBD5E1 transparent;
  border-radius: 50%;
}

.icon {
  &.icon-trash-white {
    background-image: url("../../images/iconSVG/elements/icon_trash_wh.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
  }

  &.icon-heart-outline-white {
    background-image: url("../../images/iconSVG/icon-heart-outline-white.svg");
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
  }

  &.icon-liked {
    display: inline-block;
    background-image: url("../../images/iconSVG/icon-heart-red.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 18px;
    height: 16px;
  }

  &.icon-play {
    background-image: url("../../images/iconSVG/play.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    height: 30px;
  }
}
