.single-custom-select-container {
    cursor: pointer;

    .single-custom-select__indicator {
        svg {
            width: 16px;
            height: 16px;
            background-image: url("../../images/iconSVG/custom-select/chevron-down.svg") !important;
            background-repeat: no-repeat;
            background-position: center;

            path {
                display: none;
            }
        }
    }
}

.single-custom-select__value-container {
    cursor: pointer;
}

.single-custom-select__menu-portal {
    z-index: 1001 !important;
}

.single-custom-select__control {
    padding: 8px 12px 8px 12px;
    border-radius: 6px;
    border: 1px solid #cbd5e1;
    background: #fff;
    box-sizing: border-box;
    transition: all 150ms ease-in-out;
    cursor: pointer;

    &.single-custom-select__value-container--has-value,
    &.custom-select__control--is-focused,
    &:hover {
        border: 1px solid #94a3b8;
    }

    .single-custom-select__single-value,
    .single-custom-select__placeholder {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }
}

.single-custom-select__menu {
    padding: 5px 5px 5px 5px;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #f1f5f9;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);

    .single-custom-select__option {
        padding: 6px 8px 6px 12px;
        cursor: pointer;

        color: #334155;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        transition: all 150ms ease-in-out;

        &:hover {
            background: #f1f5f9;
            border-radius: 6px;
        }
    }
}
