@import "src/components/styles/index";

.subscribed-account-section {
  margin-bottom: 28px;

  .subscribed-account-header {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    .subscribed-account-name {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.012em;
      color: #0F172A;
    }
  }
}

.guest-cameras-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-between;
  gap: 21px;
}

.guest-photo-view {
  .guest-photo-header {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;

    .guest-photo-time,
    .guest-photo-date {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #0F172A;
    }
  }

  .guest-photo-location {
    margin-bottom: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0F172A;
  }

  .guest-photo {
    position: relative;
    line-height: 0;
    width: 100%;
    margin-bottom: 24px;
    background-color: #F3F5F7;
    min-height: 596px;
    align-items: center;
    display: flex;

    img {
      line-height: 0;
      width: 100%;
    }

    .guest-photo-next,
    .guest-photo-prev {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 60px;
      height: 60px;
      background: rgba(255, 255, 255, 0.15);
      top: 50%;
      margin-top: -30px;
      cursor: pointer;
      transition: background-color 150ms ease-in-out;

      &:hover {
        background: rgba(255, 255, 255, 0.5);
      }
    }

    .guest-photo-prev {
      right: 0;
    }

    .guest-photo-next {
      left: 0;
    }
  }

  .guest-photo-weather {
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #0F172A;
      margin-bottom: 16px;
    }

    .guest-photo-weather-data {
      display: flex;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #0F172A;

      div {
        margin-right: 24px;
      }
    }
  }
}

.icon {
  &.icon-arrow-left {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../images/iconSVG/photo/arr-left.svg");
    width: 15px;
    height: 30px;
  }

  &.icon-arrow-right {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../images/iconSVG/photo/arr-right.svg");
    width: 15px;
    height: 30px;
  }
}

//icons
.subscribed-account-icon {
  background-image: url("../../images/iconSVG/header/user.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
