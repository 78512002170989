@import "src/components/styles/index";

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  margin-top: 120px;
  height: 352px;
}

.footer-body {
  display: flex;
  align-items: center;
  width: $size_list_desktop;
  justify-content: space-between;
}

.footer-body-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
}

.footer-body-icons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.footer-line {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $size_list_desktop;
  margin: 1px;
  height: 96px;
}

.footer-line-txt {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $color_400;
}

.footer-site {
  @include title-link;

}

.footer-cite-page {
  @include title-link;
  color: $color_red;
  margin-left: 12px
}

.footer-nav-list {
  margin-top: 36px;
}

.footer-nav-list-item {
  @include title-link;
  color: $color_400;
  margin-right: 48px;
  transition: color 100ms ease-in-out;

  &:hover {
    color: #E42F31;
  }
}

.footer-brand-logos {
  display: flex;
  align-items: center;
  gap: 35px;
}

.onx-footer-logo {
  display: inline-block;
  background-image: url("../../images/iconSVG/onx/hunt_md_light_bg.svg");
  background-repeat: no-repeat;
  width: 138px;
  background-size: contain;
  height: 57px;
}

//icon

.footer-logo {
  display: inline-block;
  background-image: url("../../images/iconSVG/header/logo.svg");
  background-repeat: no-repeat;
  width: 90px;
  height: 90px;
}

.footer-market_google {
  background-image: url("../../images/images/footer-google-market.png");
  background-repeat: no-repeat;
  width: 166px;
  height: 50px;
  margin-bottom: 16px;
}

.footer-market_apple {
    background-image: url("../../images/images/footer-apple-market.png");
  background-repeat: no-repeat;
  width: 166px;
  height: 50px;
}