.form-row {
    margin-bottom: 18px;

    &.form-row-horizontal {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 18px;

        .form-group {
            margin-bottom: 0;

            .form-group-calendar {
                position: absolute;
                top: 20px;
                z-index: 1;
            }
        }

        // nested horizontal row
        .form-row-horizontal {
            gap: 12px;
        }
    }

    .form-group {
        width: 100%;
        .select {
            width: 100%;
        }
    }
}

.modal-video-player {
    display: block;
    width: 100%;
}
