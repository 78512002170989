.create-folder-modal {
  .cameras-list-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 18px;
  }

  .inputError {
    width: 100%;
    padding: 8px 12px 8px 12px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #e42f31;
    border-radius: 6px;
    box-sizing: border-box;
    outline: none;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0f172a;
    margin-bottom: 3px;

    transition: border 150ms ease-in-out;

    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #94a3b8;
    }

    &:hover {
      border: 1px solid #94a3b8;
    }

    &:active {
      border: 1px solid #94a3b8;
    }

    &:focus {
      border: 1px solid #94a3b8;
    }

    &:not(:placeholder-shown) {
      border: 1px solid #cbd5e1;
    }

    &:disabled {
      background: #f3f5f7;
      border: 1px solid #cbd5e1;
    }

    &.not-valid {
      border: 1px solid #e42f31;
    }
    border: 1px solid #e42f31;
  }

  .textareaError {
    width: 100%;
    padding: 8px 12px;
    gap: 10px;
    background: #ffffff;
    border: 1px solid #e42f31;
    border-radius: 6px;
    outline: none;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0f172a;
    transition: border 150ms ease-in-out;

    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #94a3b8;
    }

    &:hover {
      border: 1px solid #94a3b8;
    }

    &:active {
      border: 1px solid #94a3b8;
    }

    &:focus {
      border: 1px solid #94a3b8;
    }

    &:not(:placeholder-shown) {
      border: 1px solid #cbd5e1;
    }

    &:disabled {
      background: #f3f5f7;
      border: 1px solid #cbd5e1;
    }

    &.not-valid {
      border: 1px solid #e42f31;
    }
  }

  .error {
    font-size: 14px;
    margin: 10px 0px 0px 5px;
    color: red;
  }
}

.delete-folder-modal {
  .modal-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
}
