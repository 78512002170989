.modal-text-block {
    text-align: center;
    .modal-text {
        font-family: "Inter";
        color: #0f172a;
        font-size: 23px;
        line-height: 34px;
        margin-bottom: 20px;
        font-weight: 700;
    }
}

.modal-input-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;

    input {
        display: flex;
        text-align: center;
        font-size: 20px !important;
        height: 25px;
        width: 85%;

        &::placeholder {
            font-size: 20px !important;
        }
    }

    .error-block {
        .modal-text-error {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #e42f31;
            text-align: left;
        }
    }
}

.modal-buttons-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 20px;

    .btn-yes {
        width: 100px;
    }
}
