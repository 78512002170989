.no-photos-section {
  width: 100%;
  text-align: center;

  .no-photos-image {
    display: inline-block;
    width: 320px;
    height: 320px;
    background-image: url("../../images/images/no-photos.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 36px;
  }

  .no-photos-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #0F172A;
  }
}