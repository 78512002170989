.camera-info-preview {
  gap: 10px;
  position: relative;
  padding: 15px;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 16px;

  .camera-name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #0F172A;
    margin-bottom: 15px;
  }

  .camera-info-provider {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.05em;
    color: #94A3B8;
  }

  .camera-info-last-seen {
    .camera-info-last-seen-time {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #0F172A;
    }
  }

  .camera-photos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    padding: 10px 0;
    gap: 10px;
    position: relative;

    .suspended-label {
      position: absolute;
      bottom: 0;
      left: 50%;
      background: #0F172A;
      padding: 2px 4px;
      border-radius: 3px;
      color: #FFFFFF;
      font-weight: 500;
      text-transform: capitalize;
      transform: translateX(-50%);
    }
  }

  .camera-photo {
    display: inline-block;

    img {
      height: 100px;
    }
  }

  .camera-info-section {
    margin-bottom: 10px;
  }

  .camera-item-stats {
    margin-bottom: 10px;

    .camera-item-stats-txt {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #0F172A;
    }
  }

  .camera-info-last-seen {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }
  }
}
.camera-item-controls {
  position: absolute;
  top: 20px;
  right: 20px;
}

.card-item-firmware-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 24px;
  right: 50px;
  background-image:  url("../../images/iconSVG/microchip-red.svg");
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.camera-subscription-status {
  display: inline-block;
}

.camera-subscription-status {
  display: block;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
  margin-bottom: 6px;

  &.over-limit {
    background-color: #E42F31;
  }

  &.close-to-limit {
    background-color: #FF8C22;
  }

  &.holder {
    visibility: hidden;
  }
}
