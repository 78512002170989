body {
  font: 14px 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #0F172A;
}

h1, h2, h3, h4, h5, h6 {
  color: #0F172A;
  font-style: normal;
  font-weight: 600;
}

h1 {
  font-weight: 800;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -0.012em;
}

h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.0075em;
}

h3 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.006em;
}

h4 {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.005em;
}

ul {
  li {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0F172A;
  }
}

blockquote {
  display: inline-block;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-left: 2px solid #E3E3E3;
  padding-left: 29px;
}
