.photos-list-container {
  &.photo-gallery {
    &.two {
      .photo-item {
        width: 348px;
      }

      .photos-list {
        gap: 20px;
      }

    }

    .photos-list {
      gap: 20px;
    }

  }
}


.photo-tags {
  margin-bottom: 12px;
}

.camera-photo-recognition-row.camera-photo-recognition-row-without-location {
  justify-content: flex-end;
}

.camera-photo-preview {
  margin-top: 50px;
}

.gallery-section {
  padding-top: 0;

  .sticky-filters {
    padding-top: 28px;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
  }
}
