@import "src/components/styles/index";


.cov-datepicker-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;

  .isRange {
    min-width: 220px;
  }

  .cov-datepicker {
    padding-right: 30px;
  }

  img.cov-datepicker-icon {
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translateY(-50%);
  }
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: $color_300;
  color: $color_900;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.cov-datepicker-header {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;

  .cov-datepicker-header-title {
    color: $color_900;
    font-size: 16px;
    margin-bottom: 4px;
    font-weight: 500;
  }

  .cov-datepicker-header-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;

    .cov-datepicker-header-controls-selects {
      select {
        font-size: 14px;
        border: none;
        text-align: center;
        outline: none;

        &:first-child {
          margin-right: 6px;
        }
      }
    }

    button {
      border: none;
      background: none;
      cursor: pointer;
      padding: 0;
      width: 20px;
      align-items: center;
      justify-content: center;

      img {
        width: 10px;
      }
    }
  }
}

.cov-datepicker {
  padding: 8px 12px 8px 12px;
  height: 40px;
  width: 100%;
  background: $snow;
  border: 1px solid $color_300;
  border-radius: 6px;
  box-sizing: border-box;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $color_900;
  transition: border 150ms ease-in-out;

  &.isRange {
    min-width: 220px;
  }
}

.react-datepicker-popper {

  .react-datepicker__triangle {
    color: $snow!important;
    fill: $snow!important;
    stroke: $color_300!important;
  }
}

.react-datepicker {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  border: 1px solid $color_300;

  .react-datepicker__header {
    background-color: $snow;
    border-bottom: 1px solid $color_300;
  }

  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    background: $color_900;
    color: $color_50;
    &:hover {
      background: $color_800;
      color: $color_50;
    }
  }
}