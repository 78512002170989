.camera-stats-wrapper {
  .stats-inner-nav {
    padding: 5px 0!important;
    margin-bottom: 12px!important;

    .nav-item:first-child {
      padding-left: 0!important;
    }
  }

  .stats-main-nav {
    padding: 5px 0!important;
    margin-bottom: 0!important;
    .nav-item:first-child {
      padding-left: 0!important;
    }
  }

  .reports-filters-line:first-child {
    margin-bottom: 24px;
  }
}
