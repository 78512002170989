@import "src/components/styles/index";

.camera-view {
  display: flex;

  .camera-view-short {
    margin-right: 21px;
    width: 268px;

    .camera-item {
      margin: 0 0 16px 0;
    }

    .visited-cameras {
      margin-top: 16px;
    }
  }
}

.photo-tags {
  margin-bottom: 12px;
}

.load-more-photos {
  margin-top: 24px;
}

.camera-photo-view {
  .camera-photo-header {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;

    .camera-photo-time,
    .camera-photo-date {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #0F172A;
    }
  }

  .camera-photo-location {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0F172A;
  }

  .camera-photo {
    position: relative;
    line-height: 0;
    width: 100%;
    margin-bottom: 24px;
    background-color: #F3F5F7;
    min-height: 596px;
    align-items: center;
    display: flex;

    .content-loader {
      width: 100%;
    }

    img {
      line-height: 0;
      width: 100%;
      opacity: 1;
      transition: opacity 150ms ease-in-out;
      width: 800px;

      &.image-loading {
        opacity: 0;
      }
    }

    .camera-photo-next,
    .camera-photo-prev {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 60px;
      height: 60px;
      background: rgba(255, 255, 255, 0.15);
      top: 50%;
      margin-top: -30px;
      cursor: pointer;
      transition: background-color 150ms ease-in-out;
      z-index: 1;

      &:hover {
        background: rgba(255, 255, 255, 0.5);
      }
    }

    .camera-photo-prev {
      right: 0;
    }

    .camera-photo-next {
      left: 0;
    }

    .photo-view-state {
      position: absolute;
      right: 20px;
      top: 20px;
      padding: 2px 6px;
      background: #FFFFFF;
      border-radius: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #000000;
      cursor: pointer;

      &.success {
        background: #31A845;
        color: #FFFFFF;
      }

      &.error {
        background: #E42F31;
        color: #FFFFFF;
      }
    }
  }

  .camera-photo-weather {
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #0F172A;
      margin-bottom: 16px;
    }

    .camera-photo-weather-data {
      display: flex;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #0F172A;

      div {
        margin-right: 24px;
      }
    }
  }

  .camera-photo-recognition-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
    min-height: 41px;

    .toggle-switch-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #000000;
    }
  }

  .camera-photo-controls-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    gap: 12px;

    button, .btn {
      white-space: nowrap;
    }
  }
}

.camera-photos-select-all {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;

  .select-all-on-camera {
    cursor: pointer;
  }
}

.all-recognitions-tags-btn {
  margin-left: 8px;
}

.camera-photo-controls-right {
  display: flex;
  align-items: center;
}

.recognition-tags-view-wrapper {
  margin-bottom: 12px;
  display: flex;
  align-items: center;

  &.editMode {
    align-items: flex-end;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .recognition-tags-view {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .recognition-tags-view-label {
    display: flex;
    align-items: center;

    .recognition-text {
      position: relative;
      color: #d32f2f;
      font-size: 14px;
      font-weight: 500;
    }

    .recognition-icon {
      margin-right: 4px;
    }
  }
  .recognition-tags-view-tags {
    display: flex;
    align-items: center;
    margin-top: 8px;
    .tag {
      padding: 3px 6px;
      text-decoration: none;
      border-radius: 6px;
      line-height: 14px;
      font-size: 14px;
      background: $color_900;
      color: #fff;
      border: 1px solid $color_900;
      margin-right: 4px;
      margin-bottom: 4px;
      transition: background 0.2s ease, border 0.2s ease;
    }

    .covert-tags-input-wrapper {
      width: 100%;
    }
  }
  .recognition-tags-view-tags-edit {
    width: 52px;
    margin-left: 8px;
  }
  .recognition-tags-view-tags-edit-ctrls {
    display: flex;
    margin-left: 8px;
    margin-bottom: 12px;

    button:last-child {
      margin-left: 4px;
    }
  }
}

.recognition-detection {
  position: absolute;
  border: 2px solid #d32f2f;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    display: none;
  }

  &.smallTagsBtn {
    button .button-text-controller {
      display: none;
    }
  }

  &:hover {
    background: rgba(255, 255, 255, 0.3);
    button {
      display: flex;
    }
  }

  transition: opacity 300ms ease-in-out, background 0.2s ease;

  &.hidden {
    opacity: 0;
  }

  &.color-0 {
    border-color: #d32f2f;
  }

  &.color-1 {
    border-color: #64dd17;
  }

  &.color-2 {
    border-color: #aed581;
  }

  &.color-3 {
    border-color: #f57c00;
  }

  &.color-4 {
    border-color: #2962ff;
  }

  &.color-5 {
    border-color: #ffcdd2;
  }

  &.color-6 {
    border-color: #a1887f;
  }

  &.color-7 {
    border-color: #ffca28;
  }
}

.recognition-text {
  position: absolute;
  display: block;
  color: #d32f2f;
  font-size: 12px;
  font-weight: bold;

  transition: opacity 300ms ease-in-out;

  &.hidden {
    opacity: 0;
  }

  &.color-0 {
    color: #d32f2f;
  }

  &.color-1 {
    color: #64dd17;
  }

  &.color-2 {
    color: #aed581;
  }

  &.color-3 {
    color: #f57c00;
  }

  &.color-4 {
    color: #2962ff;
  }

  &.color-5 {
    color: #ffcdd2;
  }

  &.color-6 {
    color: #a1887f;
  }

  &.color-7 {
    color: #ffca28;
  }
}

.photo-weather {
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $color_900;
    margin-bottom: 16px;
  }
}

.gallery-photo-view {
  .gallery-camera-photo {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #F3F5F7;
    margin-bottom: 24px;

    .camera-photo {
      width: auto;
      min-height: auto;
      margin-bottom: 0;

      &.camera-photo-loading {
        width: 100%;
        min-height: 600px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
