
.account-device-info {
  width: 100%;
}

.account-device-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid #E2E8F0;
  padding-bottom: 12px;
  margin-bottom: 12px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .info-category {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0F172A;
  }

  .info-value {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #0F172A;
  }
}

.line-camera-name {
  display: flex;
  align-items: center;
  gap: 21px;

  .line-camera-photo {
    font-size: 0;
    line-height: 0;

    img {
      height: 38px;
    }
  }

  .line-camera-name {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
  }
}

.line-camera-status {
  padding: 2px 4px;
  border-radius: 3px;
  color: #FFFFFF;
  white-space: nowrap;
  font-weight: 500;
  text-transform: capitalize;

  &.active {
    background: #31A845;
  }
  &.not-active {
    background: #E42F31;
  }
  &.photo-limit-reached {
    background: #E42F31;
  }
  &.suspend {
    background: #0F172A;
  }
}

.line-camera-btns {
  display: flex;
  flex-direction: column;
}

.remove-line-icon {
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.onx-integration-modal {
  text-align: center;

  .onx-logo-container {
    margin-bottom: 20px;
  }

  .onx-modal-logo {
    display: inline-block;
    background-image: url("../../../images/iconSVG/onx/hunt_md_light_bg.svg");
    background-repeat: no-repeat;
    width: 138px;
    height: 36px;
    background-size: cover;
  }
}

.line-subscription-description {
  margin-bottom: 20px;
  color: #0F172A;
  font-size: 14px;
  font-weight: 500;
}
