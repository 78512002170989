@import "src/components/styles/index";

.camera-view {
  display: flex;

  .camera-view-short {
    margin-right: 21px;
    width: 268px;

    .camera-item {
      margin: 0 0 16px 0;
    }

    .visited-cameras {
      margin-top: 16px;
    }
  }
}

.camera-weather-today {
  margin-top: 16px;

  .camera-weather-today-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-size: 16px;
    border-bottom: 1px solid $color_200;
    margin-bottom: 12px;
    color: $color_900;
    padding-bottom: 12px;

    &:last-child {
      border-bottom: none;
    }
  }
}


.camera-weather-table {
  thead {
    background: #f3f5f7;

    th {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 24px;
    }
  }
  tbody {
    tr {
      td {
        background: #ffffff;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: $color_900;

        &.cell-placeholder {
          text-align: center;
        }

        &.nowrap {
          white-space: nowrap;
        }

        .cell-with-icon {
          display: flex;
          align-items: center;

          &.nowrap {
            white-space: nowrap;
          }

          strong {
            text-transform: capitalize;
          }
        }

        .cell-max-min-temp {
          display: flex;
          align-items: center;
          justify-content: center;

          strong:first-child {
            padding-right: 4px;
            border-right: 0.5px solid $color_900;
          }

          strong:last-child {
            padding-left: 4px;
          }
        }

        .weather-table-icon {
          margin-right: 4px;
        }
      }
    }
  }
}
