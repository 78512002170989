@import "src/components/styles/index";

.search {
  position: relative;
  width: 476px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #333F51;
  outline: none;
  padding: 0 0 0 22px;

  .search-input {
    padding-left: 52px;
    height: 40px;
    border: 1px solid transparent;
    width: 100%;
    background: #F3F5F7;
    border-radius: 8px;
    box-sizing: border-box;
    transition: border 300ms ease-in-out;

    &:focus-visible,
    &:focus,
    &:hover {
      outline: none;
      border: 1px solid #94A3B8;
      border-radius: 8px;
    }
  }

  .search-icon {
    position: absolute;
    width: 18px;
    top: 12px;
    left: 40px;
  }

  input::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #94A3B8;
  }
}