@import "src/components/styles/index";

.multi-custom-select-container {
    cursor: pointer;

    .multi-custom-select__indicator {
        svg {
            width: 16px;
            height: 16px;
            background-image: url("../../images/iconSVG/custom-select/chevron-down.svg");
            background-repeat: no-repeat;
            background-position: center;

            path {
                display: none;
            }
        }
    }
    .multi-custom-select__clear-indicator {
        svg {
            display: none;
        }
    }
}

.multi-custom-select__control {
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid #cbd5e1;
    background: #fff;
    box-sizing: border-box;
    transition: all 150ms ease-in-out;
    cursor: pointer;

    &.multi-custom-select__value-container--has-value &.custom-select__control--is-focused,
    &:hover {
        padding: 8px 12px;
        border-radius: 6px;
        background: #fff;
        box-sizing: border-box;
        transition: all 150ms ease-in-out;
        cursor: pointer;

        border: 1px solid #94a3b8;
    }

    .multi-custom-select__single-value,
    .multi-custom-select__placeholder {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }
}

.multi-custom-select__clear-indicator {
    display: none;
}

.multi-custom-select__menu-portal {
    z-index: 1001 !important;
}

.multi-custom-select__menu {
    padding: 5px;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #f1f5f9;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);

    .multi-custom-select__option {
        padding: 6px 8px 6px 12px;
        cursor: pointer;

        color: #334155;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        transition: all 150ms ease-in-out;

        &:hover {
            background: #f1f5f9;
            border-radius: 6px;
        }
    }
}

.multi-custom-select__multi-value {
    display: flex;
    align-items: center;
    margin: 0 4px 4px 0;
    background-color: $color_900;
    border-radius: 6px;
    color: #fff;
    padding: 3px 6px;

    svg {
        width: 16px;
        height: 16px;
        background-image: url("../../images/iconSVG/custom-select/close_white.svg");
        background-repeat: no-repeat;
        background-position: center;

        path {
            display: none;
            fill: #fff;
        }
    }

    .multi-custom-select__multi-value__label {
        align-self: center;
        padding-right: 8px;
    }
}
