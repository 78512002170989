.camera-subscription-status {
  display: block;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
  margin-bottom: 6px;

  &.over-limit {
    background-color: #E42F31;
  }

  &.close-to-limit {
    background-color: #FF8C22;
  }

  &.holder {
    visibility: hidden;
  }
}

.guest-camera-item {
  display: inline-block;
  width: 268px;
  height: 228px;
  padding: 15px;
  gap: 10px;
  background: #FFFFFF;
  border-radius: 8px;
  text-decoration: none;
  color: #0F172A;
  box-sizing: border-box;

  .guest-camera-photo {
    text-align: center;
    padding: 16px 10px;
    margin-bottom: 24px;

    img {
      height: 100px;
    }
  }

  .guest-camera-name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #0F172A;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
  }

  .guest-camera-model {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.05em;
    color: #333F51;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
  }
}
