
.photo-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DFDFE0;
  padding-bottom: 18px;

  .photo-filters-left {
    display: flex;
    align-items: center;
  }

  .photo-filters-right {
    display: flex;
    align-items: center;
  }

  .photo-list-size {
    display: flex;
    align-items: center;

    .icon {
      cursor: pointer;
    }

    .icon + .icon {
      margin-left: 12px;
    }
  }

  .btn {
    margin-left: 12px;
  }

  .toggle-switch-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
  }

  .select-files {
    margin-left: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
    transition: color 100ms ease-in-out;

    &:hover {
      color: #E42F31;
    }
  }

  .total-photos-count {
    margin-left: 12px;
    font-weight: bold;
  }
}

.photo-select-mode-left {
  display: flex;
  align-items: center;

  .btn {
    margin-left: 0;
    margin-right: 12px;
  }

  .select-mode-text {
    margin-right: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
  }
}

.photo-select-mode-right {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}


.icon {
  &.icon-rows {
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../../images/iconSVG/rows.svg");

    &:hover,
    &.active {
      background-image: url("../../../../images/iconSVG/rows-active.svg");
    }
  }

  &.icon-cards {
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../../images/iconSVG/cards.svg");

    &:hover,
    &.active {
      background-image: url("../../../../images/iconSVG/cards-active.svg");
    }
  }
}