.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .error-text {
    font-size: 24px;
    font-weight: 700;
    font-family: Inter;
    margin-bottom: 40px;
  }
}
