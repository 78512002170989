@import "src/components/styles/colors";


@mixin page-title {
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-size: 28px;
    font-weight: 900;
  }

  @mixin title-link {
    text-decoration: none;
    font-style: normal;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $color_900;
  }

  @mixin title-setting-camera {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $color_900;
  }

  @mixin h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 48px;
    color: $color_900;
  }

  @mixin h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    color: $color_900;
  }

  @mixin h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $color_900;
  }

  @mixin h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: $color_900;
  }



