
.create-guest-account-modal {
  .form-group:last-child {
    margin-bottom: 18px;
  }

  .guest-account-camera-list {
    .guest-account-camera-list-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #0F172A;
      margin-top: 0;
      margin-bottom: 18px;
    }
  }

  .checkbox-table {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
