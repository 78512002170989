@import "src/components/styles/index";

.radio-button-section{
    display: flex;
}
.radio-button-label{
    display: flex;
    align-items: center;
    justify-content: start;
    // width: 100%;
    margin-right: 20px;
}

.radio-button-input{
     margin-right: 10px;

}