.custom-dropdown {
    position: relative;

    ul {
        width: 118% !important;
        margin-left: -14% !important;
        margin-top: 2px !important;

        .options-link-block {
            display: flex;
            align-items: center;
            height: 38px;
            padding: 6px 8px 6px 12px;
            cursor: pointer;
            transition: background-color 150ms ease-in-out;
            box-sizing: border-box;

            &.selected {
                background: #f3f5f7;
            }

            &:hover {
                background-color: #f3f5f7;
            }

            &.active {
                background-color: #f3f5f7;
            }

            .option-link {
                font-size: 14px;
                font-family: "Inter";
                font-weight: 400;
                color: #0f172a;
                text-decoration: none;
            }
        }
    }
}
