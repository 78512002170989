.nav {
  display: inline-block;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 5px;
  margin-bottom: 18px;

  .nav-item {
    position: relative;
    display: inline-block;
    padding: 6px 12px;
    text-decoration: none;
    background: #FFFFFF;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    gap: 10px;

    .nav-item-icon-container {
      position: absolute;
      top: -4px;
      right: -4px;
      z-index: 1;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #333F51;
      margin: 0;
      transition: color 100ms ease-in-out;
    }

    &:hover,
    &.active {
      p {
        color: #E42F31;
      }
    }
  }
}
