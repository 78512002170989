.stream-wrapper {
  display: flex;
  min-height: 500px;
  flex-direction: column;
}

.stream-loader-wrapper {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stream-placeholder {
  width: 100%;
  height: 500px;
  background: #F3F5F7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #E42F31;
  font-weight: 500;
}
