.map-page-content {
  margin: 0 auto;
}

.map-wrapper {
  width: 100%;
}

.map {
  height: 80vh;
  width: 100%;
  position: relative;
  margin-top: -31px;
  margin-bottom: -120px;
}

.map-title {
  position: absolute;
  top: 31px;
  left: 0;
  right: 0;
}
