.dnd-block {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 22.5px;
    width: 100%;
    padding-bottom: 18.5px;

    &.row {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 18px;
        cursor: pointer;
    }

    .draggable-block {
        &.row {
            width: 100%;
        }
    }
}

.dnd-block :nth-child(4n + 4) {
    margin-right: 0;
}
