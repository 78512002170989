.dropdown {
  position: relative;
  margin-left: 12px;

  .menu {
    position: absolute;
    background: #FFFFFF;
    list-style-type: none;
    right: 0;
    margin: 0;
    padding: 5px;
    z-index: 2;
    width: 224px;
    border: 1px solid #F1F5F9;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.09));
    border-radius: 6px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 150ms ease-in-out;

    &.open {
      visibility: visible;
      opacity: 1;
    }
  }

  .menu > li {
    margin: 0;
    padding: 6px 12px;
    background-color: #FFFFFF;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #334155;
    cursor: pointer;
    transition: background-color 150ms ease-in-out;
  }

  .menu > li:hover {
    background: #F1F5F9;
    transition: background-color 150ms ease-in-out;
  }

  .menu > li > button {
    width: 100%;
    height: 100%;
    text-align: left;
    background: none;
    color: inherit;
    border: none;
    margin: 0;
    cursor: pointer;
  }

  .dropdown-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px;
    height: 36px;
    border: 1px solid #CBD5E1;
    background-color: white;
    border-radius: 6px;
    padding-right: 4px;

    p {

    }
  }
}
