@import "src/components/styles/index";

.btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    gap: 10px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: background-color 150ms ease-in-out;
    height: 36px;
    box-sizing: border-box;
    text-decoration: none;

    &[disabled] {
        cursor: not-allowed;
    }

    .btn-spinner {
        position: absolute;
    }

    &.lg {
        padding: 8px 16px;
        height: 40px;
    }

    &.sm {
        padding: 4px 8px;
        height: 25px;

        span {
            font-size: 12px;
        }
    }

    &.w100 {
        width: 100%;
    }

    span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
    }

    &.btn-grey {
        border: 1px solid $color_900;
        background: $color_900;

        &:hover {
            background: #334155;
        }

        &:active {
            background: #000000;
        }

        &:disabled {
            border: 1px solid #94a3b8;
            background: #94a3b8;
        }
    }

    &.btn-red {
        background: #e42f31;

        &:hover {
            background: #bf0608;
        }

        &:active {
            background: #9d0708;
        }

        &:disabled {
            background: #f0babb;
        }
    }

    &.btn-green {
        background: #31a845;

        &:hover {
            background: #2d993f;
        }

        &:active {
            background: #278536;
        }

        &:disabled {
            background: #bbdec1;
        }
    }

    &.btn-none {
        background-color: transparent;
        border: 1px solid #0f172a;

        span {
            color: $color_900;
        }

        &:hover {
            background: #0f172a;

            span {
                color: #ffffff;
            }

            .btn-icon-edit {
                background-image: url("../../images/iconSVG/elements/icon_pencil-alt.svg");
            }

            .btn-icon-del {
                background-image: url("../../images/iconSVG/elements/icon_trash-alt.svg");
            }

            .btn-icon-view {
                background-image: url("../../images/iconSVG/elements/file_image-alt.svg");
            }
        }

        &:active {
            background: #000000;

            span {
                color: #ffffff;
            }
        }

        &:disabled {
            border: 1px solid #94a3b8;

            span {
                color: #94a3b8;
            }
        }
    }

    &.btn-red-outline {
        border: 1px solid #e42f31;
        background-color: transparent;

        span {
            color: #e42f31;
        }

        &:hover {
            background-color: #e42f31;

            span {
                color: #ffffff;
            }

            .icon-heart-red {
                background-image: url("../../images/iconSVG/icon-heart-outline-white.svg");
            }

            .icon-heart-outline-red {
                background-image: url("../../images/iconSVG/icon-heart-outline-white.svg");
            }
        }

        &:active {
            background-color: #9d0708;

            span {
                color: #ffffff;
            }
        }

        &:disabled {
            border: 1px solid #f0babb;

            span {
                color: #f0babb;
            }
        }
    }

    &.btn-grey-outline {
        border: 1px solid $color_900;
        background-color: transparent;

        span {
            color: $color_900;
        }

        &:hover {
            background-color: $color_900;

            span {
                color: #ffffff;
            }

            .icon-heart-red {
                background-image: url("../../images/iconSVG/icon-heart-outline-white.svg");
            }

            .icon-heart-outline-red {
                background-image: url("../../images/iconSVG/icon-heart-outline-white.svg");
            }
        }

        &:active {
            background-color: $color_900;

            span {
                color: #ffffff;
            }
        }

        &:disabled {
            border: 1px solid #f0babb;

            span {
                color: #f0babb;
            }
        }
    }

    &.btn-green-outline {
        border: 1px solid #31a845;
        background-color: transparent;

        span {
            color: #31a845;
        }

        &:hover {
            background-color: #31a845;

            span {
                color: #ffffff;
            }
        }

        &:active {
            background-color: #278436;

            span {
                color: #ffffff;
            }
        }

        &:disabled {
            border: 1px solid #bbdec1;

            span {
                color: #bbdec1;
            }
        }
    }

    &.btn-outline {
        border: 1px solid #e2e8f0;
        background-color: transparent;

        span {
            color: #0f172a;
        }

        &:hover {
            background-color: #0f172a;

            span {
                color: #ffffff;
            }

            .icon-refresh {
                background-image: url("../../images/iconSVG/refresh-svgrepo-alt.svg");
            }

            .icon-tags {
                background-image: url("../../images/iconSVG/icon-tags-white.svg");
            }
        }

        &:active {
            background-color: #000000;

            span {
                color: #ffffff;
            }
        }

        &:disabled {
            border: 1px solid #94a3b8;
            background-color: transparent;

            span {
                color: #94a3b8;
            }
        }
    }

    &.btn-white {
        background-color: white;

        span {
            color: $color_900;
        }
    }
}

//icons

.btn-icon-plus {
    background-image: url("../../images/iconSVG/elements/icon_plus_circle.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

//icons

.icon-camera {
    background-image: url("../../images/iconSVG/icon_camera_grey.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
}

.icon-tags {
    background-image: url("../../images/iconSVG/icon-tags.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
}

.icon-white-tags {
    background-image: url("../../images/iconSVG/icon-tags-white.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
}

.icon-camera-red {
    background-image: url("../../images/iconSVG/icon_camera_red.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
}

.btn-icon-down {
    background-image: url("../../images/iconSVG/elements/icon_chevron_down.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

.btn-icon-edit {
    background-image: url("../../images/iconSVG/elements/icon_pencil.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

.btn-icon-view {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../images/iconSVG/elements/file_image.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

.btn-icon-del {
    background-image: url("../../images/iconSVG/elements/icon_trash.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

.btn-icon-arrow {
    background-image: url("../../images/iconSVG/elements/ph_arrow-left.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

.btn-icon-trash {
    background-image: url("../../images/iconSVG/elements/icon_trash-alt.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

.btn-icon-key {
    background-image: url("../../images/iconSVG/elements/icon_key.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

.btn-icon {
    display: inline-block;

    &.icon-plus {
        background-image: url("../../images/iconSVG/elements/icon_plus_circle.svg");
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
    }

    &.icon-credit-card {
        background-image: url("../../images/iconSVG/credit-card-white.svg");
        background-repeat: no-repeat;
        width: 20px;
        height: 17px;
        background-size: contain;
    }

    &.icon-key {
        background-image: url("../../images/iconSVG/elements/icon_key.svg");
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
    }

    &.icon-eye {
        background-image: url("../../images/iconSVG/icon_eye.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
    }

    &.icon-download-alt {
        background-image: url("../../images/iconSVG/icon-download-alt.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
    }

    &.icon-trash-alt {
        background-image: url("../../images/iconSVG/elements/icon_trash-alt.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
    }

    &.icon-gear-alt {
        background-image: url("../../images/iconSVG/icon-gear-alt.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
    }

    &.icon-share {
        background-image: url("../../images/iconSVG/share.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
    }

    &.icon-heart {
        background-image: url("../../images/iconSVG/icon-heart-outline-white.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
    }

    &.icon-heart-outline-red {
        background-image: url("../../images/iconSVG/icon-heart-outline-red.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
    }

    &.icon-heart-alt {
        background-image: url("../../images/iconSVG/icon-heart-fill-alt.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
    }

    &.icon-dropdown-alt {
        background-image: url("../../images/iconSVG/elements/chevron-down-alt.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 16px;
        height: 16px;
    }

    &.icon-heart-red {
        background-image: url("../../images/iconSVG/icon-heart-red.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
    }

    &.icon-play-alt {
        background-image: url("../../images/iconSVG/play.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 15px;
        height: 15px;
        background-size: cover;
    }

    &.icon-arrow {
        background-image: url("../../images/iconSVG/errorPages/button-icon.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 16px;
        height: 16px;
    }

    &.icon-funnel {
        background-image: url("../../images/iconSVG/funnel.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 16px;
        height: 16px;
    }

    &.icon-refresh {
        background-image: url("../../images/iconSVG/refresh-svgrepo.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 16px;
        height: 16px;
    }

    &.icon-logout {
        background-image: url("../../images/iconSVG/header/logout_wh.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 16px;
        height: 16px;
    }
}
