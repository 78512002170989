@import "src/components/styles/index";

.camera-list-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.camera-list-header {
    width: 100%;

    .camera-list-header-title {
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        color: #0f172a;
        margin: 0;
    }

    .camera-list-header-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .camera-list-header-row:first-child {
        margin-bottom: 31px;
    }

    .camera-list-header-row:last-child {
        margin-bottom: 28px;
    }

    .camera-list-header-list-style {
        display: flex;

        .camera-list-view-switcher {
            cursor: pointer;
        }

        .camera-list-view-switcher + .camera-list-view-switcher {
            margin-left: 16px;
        }
    }
}

.camera-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1140px;
}

.camera-list-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1140px;
}

.camera-list-cameras-row {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.camera-list-cameras {
    display: flex;
    flex-wrap: wrap;
    gap: 22.5px;
}
.camera-list-cameras :nth-child(4n + 4) {
    margin-right: 0;
}

.folder-cameras-list {
    width: 100%;
    margin-top: 20px;
}

.folder-cameras-list:first-child {
    margin-top: 7px;
}

.folder-header {
    display: flex;
    font-size: 17px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .folder-header-section {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .folder-controls {
        display: flex;

        .folder-button {
            display: inline-block;
            margin-right: 10px;
        }
    }

    .btn + .btn {
        margin-left: 8px;
    }
}

.empty-folder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;

    p {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }

    span {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }
}

.folder-name {
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.card-item-onx-logo {
    position: absolute;
    top: 9px;
    left: 8px;
    display: inline-block;
    background-image: url("../../images/iconSVG/onx/hunt_md_light_bg.svg");
    background-repeat: no-repeat;
    width: 55px;
    height: 14px;
    background-size: cover;
}

.row-item-onx-logo {
    position: absolute;
    width: 45px;
    height: 11px;
    top: 9px;
    left: 8px;
    display: inline-block;
    background-image: url("../../images/iconSVG/onx/hunt_md_light_bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

// icons

.empty-folder-image {
    background-image: url("../../images/iconSVG/images/folder.svg");
    background-repeat: no-repeat;
    width: 320px;
    height: 320px;
    border: 7px solid white;
    border-radius: 50%;
}

.icon-cameras-row-list {
    background-image: url("../../images/iconSVG/cameraList/rowCam.svg");
    background-repeat: no-repeat;
    width: 21px;
    height: 21px;
    border: none;

    &:hover,
    &.active {
        background-image: url("../../images/iconSVG/cameraList/rowCamRed.svg");
    }
}

.icon-cameras-card-list {
    background-image: url("../../images/iconSVG/cameraList/cardCam.svg");
    background-repeat: no-repeat;
    width: 21px;
    height: 21px;
    border: none;

    &:hover,
    &.active {
        background-image: url("../../images/iconSVG/cameraList/cardCamRed.svg");
    }
}

.icon-cameras-folder-list {
    background-image: url("../../images/iconSVG/cameraList/folder.svg");
    background-repeat: no-repeat;
    width: 23px;
    height: 23px;
    border: none;

    &:hover,
    &.active {
        background-image: url("../../images/iconSVG/cameraList/folderRed.svg");
    }
}

.folder-icon {
    background-image: url("../../images/iconSVG/cameraList/folder.svg");
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    border: none;
    margin-right: 10px;
}
