@import
"../styles/form",
"../styles/tables",
"../styles/loader",
"../styles/typography";

body {
    line-height: 1.5em;
    background-color: #EEEEEE;
    -webkit-font-smoothing: antialiased;
    color: #333;
    font: 14px 'Inter', sans-serif;
}

.mr4 {
    margin-right: 4px;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.page-wrapper {
    flex: 1;
}

// Temporary
.content-loader {
    display: flex;
    justify-content: center;
}

/*
input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 12px;
    border: 1px solid #cccccc;
    background-color: #EEEEEE;
    border-radius: 3px;
    color: #333;
    font: 14px 'Inter', serif;
}*/

textarea.textarea {
    resize: vertical;
}
.recognition-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.icon-bear, &.icon-black_bear {
        background-image: url("../images/recognition/bear-w.svg");
    }

    &.icon-deer {
        background-image: url("../images/recognition/deer-w.svg");
    }

    &.icon-deer_buck {
        background-image: url("../images/recognition/deer-w.svg");
    }


    &.icon-deer_doe {
        background-image: url("../images/recognition/deer_doe-w.svg");
    }

    &.icon-hog {
        background-image: url("../images/recognition/hog-w.svg");
    }

    &.icon-empty {
        background: #f9fbfa;
        border-radius: 3px;
    }

    &.icon-other, &.icon-others {
        background-image: url("../images/recognition/other-w.svg");
    }

    &.icon-people, &.icon-human {
        background-image: url("../images/recognition/people-w.svg");
    }

    &.icon-turkey {
        background-image: url("../images/recognition/turkey-w.svg");
    }

    &.icon-vehicle {
        background-image: url("../images/recognition/vehicle-w.svg");
    }

    &.dark-icon {
        &.icon-bear, &.icon-black_bear {
            background-image: url("../images/recognition/bear-d.svg");
        }

        &.icon-empty {
            background: #333F51;
        }

        &.icon-deer {
            background-image: url("../images/recognition/deer-d.svg");
        }

        &.icon-deer_buck {
            background-image: url("../images/recognition/deer-d.svg");
        }


        &.icon-deer_doe {
            background-image: url("../images/recognition/deer_doe-d.svg");
        }

        &.icon-hog {
            background-image: url("../images/recognition/hog-d.svg");
        }

        &.icon-other, &.icon-others {
            background-image: url("../images/recognition/other-d.svg");
        }

        &.icon-people, &.icon-human {
            background-image: url("../images/recognition/people-d.svg");
        }

        &.icon-turkey {
            background-image: url("../images/recognition/turkey-d.svg");
        }

        &.icon-vehicle {
            background-image: url("../images/recognition/vehicle-d.svg");
        }
    }
}
