@import "src/components/styles/index";

/*
.modal-body {
  display: block;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 28px;
  width: 656px;
}*/

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    padding-top: 28px!important;
    padding-bottom: 6px;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 1;

    h2 {
        margin: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
    }

    .modal-close-btn {
        font-size: 0;
        line-height: 0;
        cursor: pointer;
        background: none;
        outline: none;
        border: none;
        padding: 0;
    }
}

.modal-content {
    margin-bottom: 18px;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    position: sticky;
    bottom: 0;
    background: #fff;
    padding-top: 6px;
    padding-bottom: 28px;

    .btn + .btn {
        margin-left: 12px;
    }
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    padding-top: 6px;
    padding-bottom: 28px;
    background: #fff;
    bottom: 0;
    width: 100%;

    .modal-footer-right {
        display: flex;
        .btn + .btn {
            margin-left: 12px;
        }
    }
}

.ReactModal__Overlay {
    opacity: 0;
    z-index: 1;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.modal-body {
    table {
        thead {
            tr {
                th {
                    background: #f3f5f7;
                    border: 1px solid #e2e8f0;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 24px;
                    color: #0f172a;
                }
            }
        }

        tbody {
            tr:nth-child(even) {
                background-color: transparent;
            }

            tr {
                td {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    color: #0f172a;
                }

                td {
                    label {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        color: #000000;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .checkbox-table {
        display: flex;
        align-content: center;

        label {
            margin-left: 12px;
        }
    }
}

.icon {
    &.icon-close {
        display: inline-block;
        background-image: url("../../images/iconSVG/elements/close.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}

.ReactModal__Content--after-open {
    overflow-y: auto !important;
    max-height: 90%;
    padding-top: 0!important;
    padding-bottom: 0!important;
    z-index: 100;
}
