@import "src/components/styles/index";

.camera-list-section {
  width: 1140px;

  .camera-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    width: 268px;
    height: 288px;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    transition: transform 1s ease-in-out;

    .camera-card-item-info-wrapper {
      width: 100%;
      position: relative;

      .camera-card-nav {
        display: none;
        align-items: center;
        justify-content: center;
        padding: 4px;
        width: 20px;
        height: 20px;
        border: none;
        background: none;
        cursor: pointer;
        outline: none;
        position: absolute;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        top: 50%;
        transform: translateY(-50%);

        img {
          width: 10px;
        }

        &.left {
          left: -18px;
        }

        &.right {
          right: -18px;
        }
      }
    }

    &.camera-card-with-weather {
      .camera-card-info {
        height: 106px;
      }

      .camera-card-nav {
        display: flex;
      }

      &:hover {
        .camera-card-nav {
          opacity: 1;
        }
      }
    }

    .camera-card-page {
      display: none;

      &.camera-card-active-page {
        display: block;
      }
    }

    &.suspended {
      opacity: 0.5;
    }

    &.isDraggingCard {
      width: 268px;
      height: 288px;
      border: 2px dashed #ccc;
      background-color: #eeeeee;
      transition: opacity 0.8s ease-in-out;
    }

    .pin-spinner {
      margin-bottom: 12px;
      margin-left: 2px;
    }

    .card-item-firmware-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 24px;
      right: 50px;
      background-image: url("../../images/iconSVG/microchip-red.svg");
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
    }
  }

  .camera-item-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 15px;
    width: 100%;
  }

  .camera-item-header-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .camera-item-controls {
    position: absolute;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    top: 20px;
    right: 20px;
  }

  .camera-image {
    height: 100px;
  }
}

.camera-item-name-text {
  color: $color_900;
  font-weight: 800;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
}

.camera-item-info {
  align-items: center;
  margin-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.camera-item-info-txt {
  color: $color_400;
  font-size: 14px;
  margin-right: 2px;

  &:first-child {
    margin-right: 4px;
  }
}

.camera-item-search-div {
  display: flex;
}

.camera-item-stats {
  display: flex;
  align-items: center;
}

.camera-item-stats-item {
  display: flex;
  align-items: center;
  gap: 15px;
}

.camera-item-stats-item-row {
  display: flex;
  align-items: center;
  width: 95px;
}

.camera-item-stats-item-card {
  display: flex;
  align-items: center;
}

.camera-item-stats-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.camera-item-stats-txt {
  margin: 5px 0 5px 5px!important;
  color: $color_900;
}

.camera-item-linked-list {
  position: relative;
  margin-top: 10px;

  &.showLinked {
    .camera-item-linked-list-wrapper {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0px);
    }

    .trigger {
      color: $color_red;
    }

    .camera-item-cameras-icon {
      background-image: url("../../images/iconSVG/icon_camera_red.svg");
    }
  }

  .camera-item-linked-list-wrapper {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease, transform 0.2s ease;
    position: absolute;
    top: 100%;
    right: -10px;
    background: #fff;
    border-radius: 3px;
    z-index: 1;
    transform: translateY(-10px);
    list-style: none;
    margin: 0;
    padding: 0;
    width: 150px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);

    &:after {
      content: "";
      cursor: pointer;
      display: inline-block;
      border-bottom: 5px solid #fff;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      position: absolute;
      top: -5px;
      right: 11px;
      transform: translateX(-50%);
    }

    &:before {
      content: "";
      cursor: pointer;
      display: inline-block;
      border-bottom: 6px solid rgba(0, 0, 0, 0.1);
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      position: absolute;
      top: -6px;
      right: 9px;
      transform: translateX(-50%);
    }

    ul {
      max-height: 300px;
      overflow-y: auto;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      &:hover {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0px);
      }

      li {
        display: flex;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        &:hover {
          a {
            color: $color_red;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        a {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          width: 100%;
          cursor: pointer;
          height: 100%;
          padding: 4px 8px;
          box-sizing: border-box;
          font-size: 14px;
          color: $color_900;
          text-decoration: none;
        }
      }
    }
  }

  .trigger {
    cursor: pointer;
    position: relative;
    color: $color_400;

    span {
      position: absolute;
      font-size: 8px;
      top: 0;
      font-weight: 600;
      right: 0;
      transform: translateY(-60%);
    }

    &:hover {
      color: $color_red;
    }
  }
}

.camera-item-time {
  width: 160px;
  display: flex;
  align-items: center;

  p {
    margin: 5px;
  }

  &.hidden-last-seen {
    opacity: 0;
  }
}

.camera-photo-link {
  text-decoration: none;
  cursor: pointer;
  line-height: 0;
  position: relative;

  .suspended-label {
    position: absolute;
    bottom: 0;
    left: 50%;
    background: #0F172A;
    padding: 2px 4px;
    font-size: 14px;
    border-radius: 3px;
    line-height: 14px;
    color: #FFFFFF;
    font-weight: 500;
    text-transform: capitalize;
    transform: translateX(-50%);
  }
}

.camera-name-link {
  text-decoration: none;
  cursor: pointer;
  color: $color_900;

  &.mt-8 {
    margin-top: 8px;
  }
}

//icons

.camera-item-controls-pin {
  background-image: url("../../images/iconSVG/camera/pinGrey.svg");
  width: 22px;
  height: 22px;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: transparent;

  &.pinned {
    background-image: url("../../images/iconSVG/camera/pinRed.svg");
  }

  &:hover {
    background-image: url("../../images/iconSVG/camera/pinRedHover.svg");
  }
}

.camera-item-time-icon {
  background-image: url("../../images/iconSVG/camera/icon_clock.svg");
  width: 22px;
  height: 22px;
  border: none;
  background-color: white;
  background-repeat: no-repeat;
}


.camera-item-photos-icon {
  background-image: url("../../images/iconSVG/icon_photo_gray.svg");
  width: 22px;
  background-size: contain;
  height: 22px;
  border: none;
  background-color: white;
  background-repeat: no-repeat;

  &:hover {
    background-image: url("../../images/iconSVG/icon_photo_red.svg");
  }
}


.camera-item-cameras-icon {
  background-image: url("../../images/iconSVG/icon_camera_grey.svg");
  width: 22px;
  background-size: contain;
  height: 22px;
  border: none;
  background-color: white;
  background-repeat: no-repeat;

  &:hover {
    background-image: url("../../images/iconSVG/icon_camera_red.svg");
  }
}

.camera-item-controls-setting {
  background-image: url("../../images/iconSVG/camera/setting.svg");
  width: 22px;
  height: 22px;
  border: none;
  background-color: white;
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover {
    background-image: url("../../images/iconSVG/camera/setting-red.svg");
  }
}

.camera-item-stats-card {
  background-image: url("../../images/iconSVG/camera/icon_sd_card.svg");
  width: 20px;
  height: 20px;
  border: none;
  background-color: white;
  background-repeat: no-repeat;
}

.camera-item-stats-wifi {
  background-image: url("../../images/iconSVG/camera/icon_wifi_good.svg");
  width: 20px;
  height: 20px;
  border: none;
  background-color: white;
  background-repeat: no-repeat;
}

.camera-item-stats-battery {
  background-image: url("../../images/iconSVG/camera/icon_battery_50.svg");
  width: 20px;
  height: 20px;
  border: none;
  background-color: white;
  background-repeat: no-repeat;
}

// row camera

.camera-item-row {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: white;
  text-decoration: none;
  padding: 5px 20px;
  border-radius: 8px;
  box-sizing: border-box;

  .camera-item-row-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 54px;
  }

  .camera-item-linked-list {
    margin-top: 0;
    margin-right: 10px;
  }

  &.in-gallery {
    padding: 0;

    .camera-item-header-row {
      margin-left: 0;
    }
  }

  &.suspended {
    opacity: 0.5;
  }

  .camera-item-info {
    width: auto;
    display: flex;
    margin-bottom: 0;

    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .camera-item-name-text {
    width: auto;
    padding-bottom: 8px;
  }

  .pin-spinner {
    margin-left: 2px;
  }

  &.isDragging {
    width: 100%;
    height: 100px;
    border: 2px dashed #ccc;
    background-color: #eeeeee;
    transition: opacity 0.3s ease-in-out;
  }

  .camera-item-time {
    justify-content: flex-end;
  }
}

.camera-item-row-dragging {
  width: 100%;
  height: 100px;
  border: 2px dashed #ccc;
  text-decoration: none;
  margin-bottom: 10px;
}

.camera-item-dragging {
  width: 268px;
  height: 288px;
  border: 2px dashed #ccc;
  margin-bottom: 10px;
}

.camera-row-item-dragging-over {
  width: 100%;
  height: 100px;
  border: none;
  margin-bottom: 10px;
}

.camera-item-dragging-over {
  width: 268px;
  height: 288px;
  border: none;
  margin-bottom: 10px;
}

.camera-item-header-row {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  width: 10%;
}

.camera-image-row-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .suspended-label {
    position: absolute;
    bottom: 0;
    left: 50%;
    background: #0F172A;
    padding: 2px 4px;
    font-size: 14px;
    border-radius: 3px;
    color: #FFFFFF;
    font-weight: 500;
    text-transform: capitalize;
    transform: translateX(-50%);
  }
}

.camera-image-row {
  height: 77px;
}

.camera-item-row-info {
  flex: 1;
  margin-left: 20px;
  max-width: 500px;
  overflow: hidden;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}

.camera-guest-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 268px;
  height: 228px;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  margin: 0 22px 22px 0;
  box-sizing: border-box;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $color_900;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: $color_800;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.camera-guest-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.camera-guest-image {
  width: 73px;
  height: 100px;
}

.camera-item-stats-battery-percent {
  display: flex;
  flex-direction: row;
}

.camera-subscription-status {
  display: block;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
  margin-bottom: 6px;

  &.over-limit {
    background-color: #E42F31;
  }

  &.close-to-limit {
    background-color: #FF8C22;
  }

  &.holder {
    visibility: hidden;
  }
}

.camera-item-info-row-with-weather {
  flex: 1;
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
  }
}
