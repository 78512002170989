.signin-block {
  padding: 28px;
  border-radius: 8px;
  width: 400px;
  background-color: #ffffff;
}

.signin-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
}

.signin-question {
  text-align: center;
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: 500;
  color: #0F172A;
}


.signin-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 18px;
}

.signin-block__title-container {
  display: flex;
  align-self: flex-start;
}

.signin-block__title {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #0f172a;
  margin: 0px;
}

.container {
  position: relative;
  padding: 0;
}
.start-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(./start.jpg) center center no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: cover;
}
.wrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
}

.signin-block__button {
  width: 100%;
}

.inputs-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  .input-block {
    width: 48%;
  }
}

.signin-block__form {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;

  .input-block {
    display: flex;
    flex-direction: column;
    gap: 0px;

    .input-block__label {
      padding-bottom: 6px;
    }

    .login-error-text {
      color: red;
      padding-top: 6px;
    }
  }
}

.input-block {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.input-block__label {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #0f172a;
}

.input-block__input {
  width: 100%;
  max-width: 400px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 6px !important;
  text-indent: 6px;
  border: solid 1px #cbd5e1;
  outline: none;
}

.input-block__input::placeholder {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #0f172a;
}

.input-block__password {
  position: relative;
  input {
    padding-right: 40px;
    box-sizing: border-box;
    height: 40px;
    width: 400px;
  }

  .show_password_btn {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.buttons-block {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.button--login-block {
  cursor: pointer;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  border: none;
  font-family: "Inter";
  transition: background-color 0.2s ease, color 0.2s ease, border 0.2s ease;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  background-color: #31a845;
  color: #ffffff;

  &[disabled] {
    background-color: #4d4d4d;
    border: solid 1px #4d4d4d;
    color: #fff;
    cursor: wait;
  }

  &.bordered {
    background-color: #ffffff;
    border: solid 1px #31a845;
    color: #31a845;

    &:hover {
      background-color: #31a845;
      color: #ffffff;
    }
  }

  &.red {
    color: #E42F31;
    border: 1px solid #E42F31;
    background: #fff;

    &:hover {
      background-color: #E42F31;
      color: #ffffff;
    }
  }

  &:not(.bordered):hover {
    background-color: #ffffff;
    border: solid 1px #31a845;
    color: #31a845;
  }
}

.signin-block__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
}

.signin-text-link-wrapper {
  color: #0F172A;
  text-align: center;
  margin-top: 18px;
}
.signin-text-link {
  color: #0F172A;
}
.signin-black-link {
  color: #0F172A;
  text-decoration: none;
}

.forgot-block__link {
  cursor: pointer;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #e42f31;
  text-underline-offset: 3px;
}

.signup-block__text {
  cursor: pointer;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #0f172a;
}

.signup-block__link {
  cursor: pointer;
  text-decoration: underline;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #e42f31;
  text-underline-offset: 3px;
}
.start-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 419px;
  height: 100%;
  border-radius: 8px;
  padding: 28px 0px;
  background-color: #ffffff;
}

.start-block__info {
  height: 25px;
}

.start-block__logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("./logo.svg");
  width: 100px;
  height: 100px;
}

.logo__text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #0f172a;
  margin-bottom: 9px;
}

.start-block__buttons {
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
}

.button-block {
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
}

.button {
  width: 363px;
  height: 40px;
  border-radius: 6px;
  padding: 8px, 16px, 8px, 16px;
  font-family: "Inter";
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}

.button-login {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 363px;
  height: 40px;
  border-radius: 6px;
  font-family: "Inter";
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  background-color: #31a845;
  border: solid 1px #31a845;
  color: #ffffff;
}

.button-login:hover {
  background-color: #ffffff;
  color: #31a845;
}

.button-guest {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 363px;
  height: 40px;
  border-radius: 6px;
  font-family: "Inter";
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  background-color: #ffffff;
  border: solid 1px #31a845;
  color: #31a845;
}

.button-guest:hover {
  background-color: #31a845 !important;
  color: #ffffff !important;
}

.button-signup {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 363px;
  height: 40px;
  border-radius: 6px;
  font-family: "Inter";
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  background-color: #ffffff;
  border: solid 1px #e42f31;
  color: #e42f31;
}

.button-signup:hover {
  background-color: #e42f31;
  color: #ffffff;
}

.button-block__text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  margin: 0px;
  color: #0f172a;
}

.info-block__text {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #0f172a;
  text-align: center;
}

.info-block__underline {
  cursor: pointer;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 35px;
  text-indent: 5px;
  color: #0f172a;
  text-underline-offset: 2px;
}

.logo--start {
  width: 100px;
  margin-bottom: 10px;
}

.start-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 419px;
  height: 100%;
  border-radius: 8px;
  padding: 28px 0px;
  background-color: #ffffff;
}

.start-block__info {
  height: 25px;
}

.start-block__logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("./logo.svg");
  width: 100px;
  height: 100px;
}

.logo__text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #0f172a;
  margin-bottom: 9px;
}

.start-block__buttons {
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
}

.button-block {
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
}

.button {
  width: 363px;
  height: 40px;
  border-radius: 6px;
  padding: 8px 16px 8px 16px;
  font-family: "Inter";
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}

.button-login {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 363px;
  height: 40px;
  border-radius: 6px;
  font-family: "Inter";
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  background-color: #31a845;
  border: solid 1px #31a845;
  color: #ffffff;
}

.button-login:hover {
  background-color: #ffffff;
  color: #31a845;
}

.button-guest {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 363px;
  height: 40px;
  border-radius: 6px;
  font-family: "Inter";
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  background-color: #ffffff;
  border: solid 1px #31a845;
  color: #31a845;
}

.button-guest:hover {
  background-color: #31a845 !important;
  color: #ffffff !important;
}

.button-signup {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 363px;
  height: 40px;
  border-radius: 6px;
  font-family: "Inter";
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  background-color: #ffffff;
  border: solid 1px #e42f31;
  color: #e42f31;
}

.button-signup:hover {
  background-color: #e42f31;
  color: #ffffff;
}

.button-block__text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  margin: 0px;
  color: #0f172a;
}

.info-block__text {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #0f172a;
  text-align: center;
}

.info-block__underline {
  cursor: pointer;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 35px;
  text-indent: 5px;
  color: #0f172a;
  text-underline-offset: 2px;
}

.logo--start {
  width: 100px;
  margin-bottom: 10px;
}
