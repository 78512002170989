.recognition-graph-wrapper {
  margin-bottom: 40px;
}

.recognition-graph-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  .recognition-icon {
    margin-right: 4px;
  }
}
