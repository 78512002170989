.guest-accounts-list {
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
}

.guest-account-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 9px 16px;
  gap: 10px;
  background: #FFFFFF;
  border-radius: 8px;
  margin-right: 8px;
  width: 263px;
  cursor: pointer;
  box-sizing: border-box;

  .guest-account-icon {
    display: inline-block;
  }

  .guest-account-name {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  .guest-account-key-text {
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
}

.guest-account-icon {
  display: inline-block;
  background-image: url("../../../images/iconSVG/elements/red-key.svg");
  background-repeat: no-repeat;
  width: 19px;
  height: 10px;
  background-size: cover;
  margin-right: 9px;
}

.guest-account-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button:not(:last-child) {
    margin-right: 4px;
  }
}
