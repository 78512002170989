.modal-text-block {
    text-align: center;
    .modal-text {
        font-family: "Inter";
        color: #0f172a;
        font-size: 23px;
        line-height: 34px;
        margin-bottom: 20px;
        font-weight: 700;
    }
}

.error-block {
    .modal-text-error {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #e42f31;
    }
}

.modal-input-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .error-block {
        display: flex;
        width: 85%;
        .modal-text-error {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #e42f31;
            text-align: start;
        }
    }

    .modal-buttons-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 30px;
        margin-top: 20px;

        .btn-yes {
            width: 100px;
        }
    }
}
