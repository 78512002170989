@import "src/components/styles/index";

.camera-weather-info-line {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 30px;

  .camera-weather-info-line-sun {
    display: flex;
    flex-direction: column;
    width: 45%;

    .sun-field {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    .sun-field-text {
      display: flex;
      margin-left: 4px;
      font-size: 14px;
      text-align: right;
      flex-direction: column;
    }

    .sun-field-icon {
      width: 28px;
    }
  }

  .camera-weather-info-line-temp {
    display: flex;
    flex-direction: column;
    width: 45%;

    .current-temp {
      font-size: 36px;
      color: $color_900;
      margin-bottom: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;

      .current-temp-icon {
        height: 40px;
        margin-right: 4px;
      }
    }

    .range-temp {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        width: 49%;
        font-size: 14px;
        color: $color_900;
        white-space: nowrap;
        box-sizing: border-box;

        &:first-child {
          padding-right: 8px;
          border-right: 1px solid $color_900;
        }

        &:last-child {
          padding-left: 8px;
        }
      }
    }
  }

  &.rowView {
    display: flex;
    flex-direction: row;
    padding-top: 0;

    .camera-weather-info-line-temp {
      flex-direction: row;
      align-items: center;
      width: auto;

      .current-temp {
        margin-bottom: 0;
        margin-right: 12px;
        font-size: 16px;
      }
    }

    .camera-weather-info-line-sun {
      flex-direction: row;
      align-items: center;
      width: auto;
      color: $color_900;

      .sun-field {
        margin-bottom: 0;

        &:first-child {
          margin-right: 8px;
        }
      }
    }

    .camera-weather-info-line-icon {

    }
  }
}

.photo-weather-data {
  display: flex;

  small {
    font-size: 12px;
  }

  .photo-weather-col {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  .photo-weather-range-temp {
    display: flex;
    align-items: center;
    height: 40px;

    span {
      font-size: 16px;
      color: $color_900;
      white-space: nowrap;
      box-sizing: border-box;

      &:first-child {
        padding-right: 8px;
        border-right: 1px solid $color_900;
      }

      &:last-child {
        padding-left: 8px;
      }
    }
  }

  .photo-weather-row {
    font-size: 16px;
    display: flex;
    align-items: center;
    color: $color_900;

    strong.mr4 {
      margin-right: 4px;
    }

    .photo-weather-row-text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .photo-weather-icon {
      height: 40px;
      margin-right: 4px;
    }
  }
}
