@import "src/components/styles/index";


.toggle-switch-section {
  display: flex;
  align-items: center;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  border-radius: 50px;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: $color_200;
  border-radius: 50px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}

.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(20px);
  background-color: #ffffff;
}

.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: $color_900;
}

p.toggle-switch-text {
  margin-left: 8px;
  margin-top: 0;
  margin-bottom: 0;
}

.toggle-switch-section {
  .toggle-switch-spinner {
    position: absolute;
    top: 1px;
    left: 1px;
  }
}

.toggle-switch-checked {
  .toggle-switch-spinner {
    left: 20px;
  }
}

.toggle-switch-spinner {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-color: #CBD5E1 #CBD5E1 #CBD5E1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.1s linear infinite;
  z-index: 100;
}

.toggle-switch-section {
  &.toggle-switch-disabled {
    .toggle-switch {
      .switch {
        background-color: #e2e8f0 !important;
      }
    }
  }
}