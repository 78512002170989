.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #CBD5E1 #CBD5E1 #CBD5E1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

.pin-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-color: #CBD5E1 #CBD5E1 #CBD5E1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.1s linear infinite;
}

.btn-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-color: #CBD5E1 #CBD5E1 #CBD5E1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}