.custom-dropdown {
    position: relative;

    .dropdown-trigger {
        display: flex;
        width: 90px;
        align-items: center;
        cursor: pointer;
        padding: 8px 8px 0px 0px;
        border: none;
        border-radius: 6px;
        background-color: #fff;
        color: #000;
        height: 22px;

        .general-block-subtitle {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;

            color: #000;
        }

        .option-arrow {
            background-image: url("../../images/iconSVG/images/down_arrow.svg");
            width: 16px;
            height: 16px;
            position: absolute;
            right: 12px;
        }

        .selected-value {
            display: flex;
            align-items: center;
            gap: 4px;
            border-radius: 3px;
            padding: 1px 8px;
            height: 21px;
            width: auto;
            overflow: hidden;
            white-space: nowrap;
        }

        .clear-icon {
            background-image: url("../../images/iconSVG/images/close.svg");
            width: 16px;
            height: 16px;
            margin-top: 1px;
            cursor: pointer;
        }
    }

    .dropdown-options {
        display: flex;
        flex-direction: column;
        position: absolute;
        margin-left: -12.5%;
        width: 100%;
        z-index: 1;
        top: 100%;
        left: 0;
        overflow-y: auto;
        margin-top: 1px;
        padding: 0 5px 5px 5px;
        list-style: none;
        background-color: #fff;
        border: 1px solid #F3F5F7;
        border-radius: 6px;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);

        .options-block {
            display: flex;
            align-items: center;
            height: 32px;
            padding: 6px 8px 6px 12px;
            cursor: pointer;
            transition: background-color 150ms ease-in-out;
            box-sizing: border-box;

            li {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
            }

            &.selected {
                background: #f3f5f7;
            }

            &:hover {
                background-color: #f3f5f7;
            }

            &.active {
                background-color: #f3f5f7;
            }
        }

        .option-container {
            padding: 5px;
            .option {
                padding: 6px;
                height: 22px;
                margin-left: 10px;
                cursor: pointer;

                &.selected {
                    border-radius: 6px;
                    background-color: #f3f5f7;
                    position: relative;
                }

                &.selected::after {
                    content: "";
                    background-image: url("../../images/iconSVG/images/check.svg");
                    width: 16px;

                    height: 16px;
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    transform: translateY(-50%);
                }
            }
        }
    }

    &.open {
        .dropdown-trigger {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
}
