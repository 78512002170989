table {
  border: 1px solid #E2E8F0;
  width: 100%;
  gap: 10px;
  border-collapse: collapse;

  thead {
    th {
      padding: 8px 16px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #0F172A;
      text-align: left;
      border: 1px solid #E2E8F0;
    }
  }

  tbody {

    tr:nth-child(even) {
      background-color: #F1F5F9;
    }

    td {
      padding: 8px 16px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #0F172A;
      border: 1px solid #E2E8F0;

      &.table-empty {
        text-align: center;
      }
    }
  }
}