.share-modal-socials {
  display: flex;
  align-content: center;
  justify-content: center;
  padding-bottom: 28px;
}

.share-modal-textarea {
  width: 100%;
  margin-bottom: 12px;
}

.share-subtitle {
  margin-bottom: 12px;
  margin-top: 0;
  font-size: 16px;
  text-align: center;
}

.shareModalBtn:not(:last-child) {
  margin-right: 12px;
}
