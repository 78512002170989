.graphic-block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 187px;
    width: 100%;
    margin-top: 20px;
}

.graphic-loader-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bar-chart-wrapper {
    .recharts-legend-wrapper {
        position: relative !important;
        width: 100% !important;
        height: auto !important;
        transform: translateY(-100%) !important;
    }
}

.custom-graph-tooltip {
    display: flex;
    background: #fff;
    width: 200px;
    flex-direction: column;
    padding: 8px;
    color: #0f172a;
    border-radius: 3px;
    font-size: 12px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);

    label {
        font-weight: 500;
        color: inherit;
        font-size: inherit;
    }

    ul {
        color: inherit;
        font-size: inherit;
        list-style: none;
        padding: 0;
        margin-top: 4px;

        li {
            color: #0f172a;
            font-size: 12px;
        }
    }
}
