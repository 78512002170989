.error-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 39px;

  .img-container {
    display: inline-block;
    margin-bottom: 39px;

    img {
      display: inline-block;
      font-size: 0;
      line-height: 0;
    }
  }

  .error-page-description {
    margin-bottom: 36px;
    text-align: center;

    .error-page-title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 36px;
      margin-top: 0;
    }

    .error-text-comment {
      font-size: 16px;
      font-weight: 400;
    }

    .error-text-comment + .error-text-comment {
      margin-top: 18px;
      margin-bottom: 0;
    }
  }
}
