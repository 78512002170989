@import "src/components/styles/index";

.empty-guest-image {
    background-image: url("../../images/iconSVG/images/empty_guest.svg");
    background-repeat: no-repeat;
    width: 250px;
    height: 250px;
    border: 7px solid white;
    border-radius: 50%;
}

.page-header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .page-header-controls {
        display: flex;

        .btn + .btn {
            margin-left: 12px;
        }
    }
}

.no-content {
    text-align: center;
    margin-bottom: 50px;

    .no-accounts-image {
        display: inline-block;
        background-image: url("../../images/images/no-accounts.png");
        background-repeat: no-repeat;
        width: 320px;
        height: 320px;
        background-size: cover;
        margin-bottom: 36px;
    }
}

.no-content-text {
    .no-content-title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 16px;
    }

    .no-content-subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }
}

.guest-accounts-content {
    .content-section {
        h4 {
            margin-top: 0;
            margin-bottom: 18px;
        }
    }

    .guest-accounts-list-section + .guest-accounts-list-section {
        margin-top: 28px;
    }
}
