.covert-tags-input-wrapper {
  margin-bottom: 12px;
  position: relative;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0f172a;
    margin-bottom: 6px;
    display: flex;
  }

  .covert-tags-input {
    padding: 7px 8px 0 8px;
    min-height: 40px;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    border-radius: 6px;
    box-sizing: border-box;
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0F172A;
    transition: border 150ms ease-in-out;

    &.react-tagsinput--focused {
      border: 1px solid #94A3B8;
    }

    .react-tagsinput-tag {
      padding: 3px 6px;
      border-radius: 6px;
      line-height: 14px;
      font-size: 14px;
      background: #0f172a;
      color: #fff;
      border: 1px solid #0f172a;
    }

    .react-tagsinput-input {
      padding: 0 2px;
      font-size: 14px;
      color: #0F172A;
      font-weight: 400;
      margin-bottom: 5px;
    }
  }
}

.tags-input-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  max-height: 300px;
  overflow: auto;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      cursor: pointer;
      padding: 4px 8px;
      font-size: 14px;
      color: #0F172A;

      &:first-child {
        border-radius: 6px 0 0 6px;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
      }

      &:hover, &.highlightedSuggestion {
        background: #F1F5F9;
      }
    }
  }
}
