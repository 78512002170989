.choose-subscriptions-list {
  display: flex;
  flex-direction: row;
}

.choose-subscriptions-list-right-side {
  flex: 1;
  display: flex;
  height: 500px;

  .choose-subscriptions-list-right-side-groups {
    width: 200px;
    min-width: 200px;
    border-right: 1px solid #e2e8f0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 500px;
    padding: 0 12px;
  }
}

.choose-subscriptions-list-left-side {
  width: 200px;
  border-right: 1px solid #e2e8f0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 500px;
  padding-right: 12px;
}

.subscription-btn {
  display: flex;
  padding: 9px 16px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  color: #0f172a;
  min-height: 85px;
  margin-bottom: 12px;
  background: #fff;
  transition: background 0.2s ease;

  &:hover, &.selected {
    color: #E42F31;
    border: 1px solid #E42F31;
  }

  &.add .subscription-btn-name {
    margin: 0;
  }

  &.subscription-group-btn {
    min-height: 0;
    .subscription-btn-name, .subscription-btn-group-name {
      margin: 0;
    }
  }

  .subscription-btn-recommended {
    font-size: 12px;
    background: #E42F31;
    color: #fff;
    border-radius: 3px;
    padding: 2px 4px;
    margin-bottom: 4px;
    display: inline-block;

    &.subscription-gray {
      background: #0f172a;
      margin-top: 4px;
      margin-bottom: 0;
      color: #fff;
    }
  }

  .subscription-btn-group-name {
    font-size: 16px;
    margin: 0;
    text-align: center;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .subscription-btn-name {
    text-align: center;
    font-size: 14px;
    margin: 0;
    font-weight: 400;

    .subscription-btn-name-pictures {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 4px;
    }
  }

  .subscription-btn-lines {
    font-size: 12px;
    margin: 0;
    opacity: 0.6;
    text-align: center;
  }
}

.subscription-preview {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
}

.subscription-preview-title {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
  color: #0f172a;
}

.subscription-preview-lines {
  margin: 0 0 12px 0;
  font-weight: 500;
  font-size: 16px;

  strong {
    font-weight: bold;
  }
}

.subscription-preview-examples {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    cursor: zoom-in;
    margin-bottom: 12px;
  }
}

.example-addon-image-in-modal {
  width: 100%;
  cursor: zoom-out;
}
