@import "src/components/styles/index";

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
  width: 100%;
  margin-bottom: 31px;

  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    width: $size_list_desktop;
    background-color: white;
    position: relative;


    .website-version {
      position: absolute;
      top: 6px;
      left: 64px;
      background-color: #E42F31;
      color: #FFFFFF;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 2px;
      padding-bottom: 2px;
      border-radius: 6px;
    }
  }
}

.header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  width: $size_list_desktop;
  background-color: white;
}

.header-nav-list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  height: 100%;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }

  span {
    @include title-link;
    color: $color_900;
  }

}


.header-menu-account {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 100%;

  .header-right-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $color_900;
    margin-left: 27px;

    &.header-logout:hover .icon-logout {
      background-image: url("../../images/iconSVG/header/logout-red.svg");

    }
  }
}

.header-account-item {
  .header-menu-text {
    position: relative;
  }

  &:hover {
    .header-menu-account-icon_user {
      background-image: url("../../images/iconSVG/header/user-red.svg");
    }

    .header-menu-text {
      transition: color 50ms ease-in-out;
      color: $color_red;
    }
  }
}

.header_section_link {
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $color_900;
  text-decoration: none;

  &.visit-our-website {
    color: $color_red;
  }
}

.header-nav-list-item {
  span {
    transition: color 100ms ease-in-out;
  }

  &:hover,
  &.active {
    span {
      color: $color_red
    }
  }
}

hr.header-divider {
  border-top: 1px solid #E2E8F0;
  width: 100%;
  margin: 0;
  height: 1px;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  padding: 0;
}

.header-download-apps {
  .header_section_market_apple {
    margin-left: 12px;
  }
}


//icon

.header_section_market_google {
  display: inline-block;
  background-image: url("../../images/iconSVG/header/google.svg");
  background-repeat: no-repeat;
  width: 105.76px;
  height: 30px;
}

.header_section_market_apple {
  display: inline-block;
  background-image: url("../../images/iconSVG/header/appstore.svg");
  background-repeat: no-repeat;
  width: 105.76px;
  height: 30px;
}

.header-menu-account-icon_user {
  background-image: url("../../images/iconSVG/header/user.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.header_section_logo {
  background-image: url("../../images/iconSVG/header/logo.svg");
  background-repeat: no-repeat;
  width: 76px;
  height: 76px;
  cursor: pointer;
}

.warning-icon-account-container {
  position: absolute;
  top: -16px;
  right: -16px;
  cursor: pointer;
}

.header-user-loader {
  margin-right: 10px;
}

.icon {
  &.icon-logout {
    display: inline-block;
    background-image: url("../../images/iconSVG/header/logout.svg");
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    background-position: center;

    &:hover {
      background-image: url("../../images/iconSVG/header/logout-red.svg");
    }
  }

  &.icon-warning {
    display: inline-block;
    background-image: url("../../images/iconSVG/warning-icon.svg");
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
    background-position: center;
    background-size: cover;
  }
}
